import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import Form from 'react-bootstrap/Form';
import { updateCredentialUser } from '../../authApi';
import { UserProfile } from '../../authType';
import { selectUserProfile } from '../../authSlice';
import { message } from 'antd';
type props = {
  rowData: UserProfile;
};

function ActionApproveColumn({ rowData }: props) {
  const [messageApi, contextHolder] = message.useMessage();
  const success = () => {
    messageApi.open({
      type: 'success',
      content: 'Update successfully!'
    });
  };
  const dispatch = useAppDispatch();
  const profile = useAppSelector(selectUserProfile);
  const handleOnClick = (value: boolean) => {
    dispatch(updateCredentialUser({ userId: rowData.id, isActive: value })).then((res: any) => {
      if (res.meta.requestStatus === 'fulfilled') {
        success();
      }
    });
  };

  return (
    <>
      {contextHolder}
      <Form>
        <Form.Check
          type="switch"
          id="custom-switch"
          onClick={(e: any) => {
            const value = e.target.checked;
            handleOnClick(value);
          }}
          defaultChecked={rowData.isActive}
          disabled={profile !== null && profile.email === rowData.email}
        />
      </Form>
    </>
  );
}

export default ActionApproveColumn;
