import { DateTime } from 'luxon';
import { Breadcrumb, Button, Card, Col, Container, Form, FormControl, InputGroup, Row, Spinner, Offcanvas, Modal } from 'react-bootstrap';
import ReactDatePicker from 'react-datepicker';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import {
  selectDetectedLocationDetail,
  selectDetectedLocationSearch,
  selectDrawImageLoading,
  selectDrawRectangleImage,
  selectPanoImage,
  selectPanoLoading,
  toggleShowExportPdfModal,
  selectDownloadReportLoading,
  selectUrl,
  setNumberDefectIds
} from '../../detectedLocationSlice';
import { Pannellum } from 'pannellum-react';
import Carousel from 'react-bootstrap/Carousel';
import { addPrefixToImageBase64 } from '../../../../utils/helper';
import { NavLink } from 'react-router-dom';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import { BsArrowCounterclockwise, BsZoomIn, BsZoomOut } from 'react-icons/bs';
import {
  updateDetectedData,
  createPixelMeasurement,
  getDetectedLocationDetail,
  getDrawRectangleImageById,
  getExportPDF,
  getNumberDefectIds
} from '../../detectedLocationApi';
import { Controller, useForm } from 'react-hook-form';
import _, { set } from 'lodash';
import { useState, useEffect, useRef, useCallback } from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, Colors } from 'chart.js';
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, Colors);
import './styles.scss';
import { Rnd } from 'react-rnd';
import { selectUserProfile } from '../../../auth/authSlice';
import { useParams } from 'react-router-dom';
import { message, Progress } from 'antd';
import PdfInformationFormModal from '../PdfInformationFormModal';
import { TileLayer, Marker, MapContainer, Tooltip as TooltipLeaflet } from 'react-leaflet';
import L from 'leaflet';
import LocationRedIcon from '../../../../Assets/images/location-pin-red.png';
import StatusColumn from '../StatusColumn';
import { format } from 'date-fns';
import { LuFileInput } from 'react-icons/lu';
import { IoIosArrowUp, IoIosArrowDown } from 'react-icons/io';

interface IFormInput {
  threshIou: number;
}

const SelectedLocation = new L.Icon({
  iconUrl: LocationRedIcon,
  iconSize: [32, 32],
  iconAnchor: [16, 32]
});

interface Dimension {
  top?: number;
  right?: number;
  bottom?: number;
  left?: number;
}

interface Rectangle {
  id: number;
  x: number;
  y: number;
  width: number;
  height: number;
  confidence: number;
  defectId: string;
  cornersCanvas: { x: number; y: number }[];
  corners: { x: number; y: number }[];
  dimensions?: Dimension;
  dimensionsFetched?: boolean;
  number: number;
}

interface Path {
  id: number;
  segments: PathSegment[];
  number: number;
}

interface Crack {
  boxTopLeftX: number;
  boxTopLeftY: number;
  boxBottomRightX: number;
  boxBottomRightY: number;
  confidence: number;
  defectId: number;
  id: string;
  createdAt: string;
  updatedAt: string;
}

interface PathSegment {
  start: { x: number; y: number };
  end: { x: number; y: number };
  startCanvas: { x: number; y: number };
  endCanvas: { x: number; y: number };
  distance?: number;
}

const DetectedLocationDetail = () => {
  const detectedLocationDetail = useAppSelector(selectDetectedLocationDetail);
  const pavementImageWithCrackUrl =
    detectedLocationDetail && detectedLocationDetail.detectedImages?.length ? detectedLocationDetail.detectedImages[0]?.cameraFiveCorrImage?.url : '';
  const drawRectangleImage = useAppSelector(selectDrawRectangleImage) || pavementImageWithCrackUrl;
  const userProfile = useAppSelector(selectUserProfile);
  const panoImage = useAppSelector(selectPanoImage);
  const drawImageLoading = useAppSelector(selectDrawImageLoading);
  const getPanoLoading = useAppSelector(selectPanoLoading);
  const getUrl = useAppSelector(selectUrl);
  const searchParams = useAppSelector(selectDetectedLocationSearch);
  const dispatch = useAppDispatch();
  const date = DateTime.fromFormat(`${detectedLocationDetail?.date}${detectedLocationDetail?.time}`, 'yyyyMMddHHmmssSSS');
  const [show, setShow] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [cracks, setCracks] = useState<any>([]);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const latitude = detectedLocationDetail?.latitude;
  const longitude = detectedLocationDetail?.longitude;
  const detectedLocationCache = localStorage.getItem('detectedLocation');
  const detectedLocationList = JSON.parse(detectedLocationCache || '[]');
  const { id } = useParams();
  const next = id && detectedLocationList?.includes(id) ? detectedLocationList[detectedLocationList.indexOf(id) + 1] : null;
  const previous = id && detectedLocationList?.includes(id) ? detectedLocationList[detectedLocationList.indexOf(id) - 1] : null;
  const yawPitchNext = detectedLocationDetail?.extendData?.next;
  const yawPitchPrevious = detectedLocationDetail?.extendData?.previous;
  const [pavementImage, setPavementImage] = useState<string>('view');
  const [style, setStyle] = useState<any>({});
  const [sizePosition, setSizePosition] = useState<any>({});
  const [itemUndo, setItemUndo] = useState<any>([]);
  const [index, setIndex] = useState<number>(0);
  const [pixelMeasurement, setPixelMeasurement] = useState<boolean>(false);
  const [scale, setScale] = useState<number>(1);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const imageRef = useRef<HTMLImageElement | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const [paths, setPaths] = useState<Path[]>([]);
  const [pathStartPoint, setPathStartPoint] = useState<{ x: number; y: number } | null>(null);
  const [currentPath, setCurrentPath] = useState<Path | null>(null);
  const [tempPathPoint, setTempPathPoint] = useState<{ x: number; y: number } | null>(null);
  const [totalDistance, setTotalDistance] = useState<number[]>([]);
  const [shapeNumber, setShapeNumber] = useState<number>(0);

  const [messageApi, contextHolder] = message.useMessage();
  const isDownloadLoading = useAppSelector(selectDownloadReportLoading);
  const [processed, setProcessed] = useState(0);
  const [imageLoaded, setImageLoaded] = useState(false);

  const [rectangles, setRectangles] = useState<Rectangle[]>([]);
  const [currentTool, setCurrentTool] = useState<string | null>(null);
  const [rectClickCount, setRectClickCount] = useState<number>(0);

  const [rectStartPoint, setRectStartPoint] = useState<{ x: number; y: number } | null>(null);
  const [tempRect, setTempRect] = useState<Partial<Rectangle>>({});

  const WIDTH_IMAGE = detectedLocationDetail && detectedLocationDetail.width ? detectedLocationDetail.width : 0; // 3840 5496
  const HEIGHT_IMAGE = detectedLocationDetail && detectedLocationDetail.height ? detectedLocationDetail.height : 0; // 1652 2108
  type DefectedTypeEnumType = {
    [key: number]: string;
  };
  const DefectedTypeEnum: DefectedTypeEnumType = {
    1: 'Footpath crack',
    2: 'Tactile tile cracks',
    3: 'Tactile tile missing',
    4: 'Tile crack',
    5: 'Tile missing',
    6: 'Grating damaged',
    7: 'Grating missing',
    8: 'Footpath uneven',
    9: 'Grating uneven'
  };

  const color = ['#ff7200', '#ff7200', '#f23645', '#fbc02d', '#4caf50', '#089981', '#00bcd4', '#2962ff', '#673ab7', '#9c27b0', '#e91e63'];

  const error = (text: string) => {
    messageApi.open({
      type: 'error',
      content: text
    });
  };
  // default load with crack
  useEffect(() => {
    if (detectedLocationDetail?.cracks) {
      setCracks(detectedLocationDetail.cracks);
    }
  }, [detectedLocationDetail]);

  const checkShapeLimit = () => {
    if (shapeNumber >= 10) {
      error('Maximum of 10 shapes reached. Cannot draw more.');
      return false;
    }
    return true;
  };

  // edit feature
  useEffect(() => {
    if (pavementImage === 'edit') {
      const obj = {} as any;
      const style = {} as any;
      // const cracks = detectedLocationDetail?.cracks;
      const scale = 0.5;
      const scaleX = 1262 / (WIDTH_IMAGE * scale);
      const scaleY = 543 / (HEIGHT_IMAGE * scale);
      cracks?.map((crack: Crack, i: number) => {
        const x = crack.boxTopLeftX * scale;
        const y = crack.boxTopLeftY * scale;
        const width = crack.boxBottomRightX * scale - crack.boxTopLeftX * scale;
        const height = crack.boxBottomRightY * scale - crack.boxTopLeftY * scale;
        obj[i] = {
          x: x * scaleX,
          y: y * scaleY,
          width: width * scaleX,
          height: height * scaleY,
          confidence: crack.confidence,
          defectId: crack.defectId
        };
        style[i] = {
          display: 'flex',
          border: 'solid 2px red'
        };
      });
      setSizePosition(obj);
      setStyle(style);
    }
  }, [pavementImage, detectedLocationDetail]);

  const handleCanvasClick = async (e: React.MouseEvent<HTMLCanvasElement, MouseEvent>) => {
    if (!checkShapeLimit()) {
      return;
    }
    const canvas = canvasRef.current;
    const img = imageRef.current;
    if (!canvas || !img || !currentTool) return;

    const rect = canvas.getBoundingClientRect();
    const scaleX = img.width / rect.width;
    const scaleY = img.height / rect.height;
    const x = (e.clientX - rect.left) * scaleX;
    const y = (e.clientY - rect.top) * scaleY;

    if (currentTool === 'rectangle') {
      if (rectClickCount === 0) {
        setRectStartPoint({ x, y });
        setRectClickCount(1);
      } else if (rectClickCount === 1 && rectStartPoint) {
        const width = x - rectStartPoint.x;
        const height = y - rectStartPoint.y;
        const cornersCanvas = [
          { x: rectStartPoint.x, y: rectStartPoint.y },
          { x, y: rectStartPoint.y },
          { x, y },
          { x: rectStartPoint.x, y }
        ];
        const corners = cornersCanvas.map((corner) => {
          return {
            x: Math.round(((corner.x * WIDTH_IMAGE) / rect.width) * scale),
            y: Math.round(((corner.y * HEIGHT_IMAGE) / rect.height) * scale)
          };
        });

        const newRect: Rectangle = {
          id: Date.now(),
          x: rectStartPoint.x,
          y: rectStartPoint.y,
          width,
          height,
          confidence: 0,
          defectId: '',
          corners,
          cornersCanvas,
          number: shapeNumber + 1
        };

        setRectangles([...rectangles, newRect]);
        setRectClickCount(0);
        setRectStartPoint(null);
        setTempRect({});
      }
    } else if (currentTool === 'path') {
      // Path Tool Handling
      if (!currentPath) {
        // Start a new path
        const newPath: Path = {
          id: Date.now(),
          segments: [],
          number: shapeNumber + 1
        };
        setCurrentPath(newPath);
        setPathStartPoint({ x, y });
      } else {
        if (pathStartPoint) {
          // Create a new segment from the stored point to the current point
          const newSegment: PathSegment = {
            startCanvas: { x: pathStartPoint.x, y: pathStartPoint.y },
            endCanvas: { x, y },
            start: {
              x: Math.round(((pathStartPoint.x * WIDTH_IMAGE) / rect.width) * scale),
              y: Math.round(((pathStartPoint.y * HEIGHT_IMAGE) / rect.height) * scale)
            },
            end: {
              x: Math.round(((x * WIDTH_IMAGE) / rect.width) * scale),
              y: Math.round(((y * HEIGHT_IMAGE) / rect.height) * scale)
            },
            distance: 0
          };
          await addSegmentToCurrentPath(newSegment);
          setPathStartPoint({ x, y });
        }
      }
    }
  };

  // Function to add a new segment and fetch its distance
  const addSegmentToCurrentPath = async (newSegment: PathSegment) => {
    setLoading(true);
    try {
      const res = await dispatch(
        createPixelMeasurement({
          x1: newSegment.start.x,
          y1: newSegment.start.y,
          x2: newSegment.end.x,
          y2: newSegment.end.y
        })
      );

      if (res.meta.requestStatus === 'fulfilled') {
        const fetchedDistance = typeof res.payload === 'number' ? res.payload : undefined;
        if (fetchedDistance === undefined) {
          error('Invalid distance received from API.');
          newSegment.distance = 0;
        } else {
          newSegment.distance = fetchedDistance;
        }
      } else {
        error(res.payload as string);
        newSegment.distance = 0;
      }
    } catch (err) {
      error('Error fetching distance from API.');
      newSegment.distance = 0;
    } finally {
      setLoading(false);
    }

    // Add the segment to the current path
    setCurrentPath((prevPath) => {
      if (prevPath) {
        return {
          ...prevPath,
          segments: [...prevPath.segments, newSegment]
        };
      } else {
        return {
          id: Date.now(),
          segments: [newSegment],
          number: paths.length + 1
        };
      }
    });
  };

  // Fetch Dimensions for each Rectangle
  useEffect(() => {
    rectangles.forEach((rect) => {
      if (!rect.dimensionsFetched) {
        const fetchDimensions = async () => {
          setLoading(true);
          try {
            const [topRes, rightRes] = await Promise.all([
              dispatch(
                createPixelMeasurement({
                  x1: rect.corners[0].x,
                  y1: rect.corners[0].y,
                  x2: rect.corners[1].x,
                  y2: rect.corners[1].y
                })
              ),
              dispatch(
                createPixelMeasurement({
                  x1: rect.corners[1].x,
                  y1: rect.corners[1].y,
                  x2: rect.corners[2].x,
                  y2: rect.corners[2].y
                })
              )
            ]);

            const newDimensions: Dimension = {};

            // Top Edge
            if (topRes.meta.requestStatus === 'fulfilled') {
              newDimensions.top = typeof topRes.payload === 'number' ? topRes.payload : undefined;
              newDimensions.bottom = newDimensions.top;
            } else {
              error(topRes.payload as string);
            }

            // Right Edge
            if (rightRes.meta.requestStatus === 'fulfilled') {
              newDimensions.right = typeof rightRes.payload === 'number' ? rightRes.payload : undefined;
              newDimensions.left = newDimensions.right;
            } else {
              error(rightRes.payload as string);
            }
            const total = (newDimensions.top || 0) + (newDimensions.right || 0) + (newDimensions.bottom || 0) + (newDimensions.left || 0);
            const roundedTotal = Math.round(total * 10) / 10;

            setRectangles((prevRects) => prevRects.map((r) => (r.id === rect.id ? { ...r, dimensions: newDimensions, dimensionsFetched: true } : r)));
            setTotalDistance([...totalDistance, roundedTotal]);
            setShapeNumber(shapeNumber + 1);
          } catch (err) {
            error('Error fetching dimensions');
          }
          setLoading(false);
        };

        fetchDimensions();
      }
    });
  }, [rectangles, dispatch]);

  const finalizeCurrentPath = () => {
    if (currentPath && currentPath.segments.length > 0) {
      const newPath: Path = {
        id: Date.now(),
        segments: currentPath.segments,
        number: shapeNumber + 1
      };
      const total = currentPath.segments.reduce((acc, segment) => acc + (segment.distance || 0), 0);
      const roundedTotal = Math.round(total * 10) / 10;

      setPaths([...paths, newPath]);
      setCurrentPath(null);
      setTempPathPoint(null);
      setPathStartPoint(null);
      setTotalDistance([...totalDistance, roundedTotal]);
      setShapeNumber(shapeNumber + 1);
    }
  };

  // Canvas Rendering
  useEffect(() => {
    const canvas = canvasRef.current;
    const img = imageRef.current;
    if (imageLoaded && pavementImage === 'view') {
      if (canvas && img) {
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx = canvas.getContext('2d');

        if (ctx) {
          ctx.clearRect(0, 0, canvas.width, canvas.height);

          // Draw Existing Cracks
          const scale = 0.5;
          cracks.forEach((item: Crack) => {
            const x = item.boxTopLeftX * scale;
            const y = item.boxTopLeftY * scale;
            const width = (item.boxBottomRightX - item.boxTopLeftX) * scale;
            const height = (item.boxBottomRightY - item.boxTopLeftY) * scale;
            ctx.beginPath();
            ctx.rect(x * scale, y * scale, width * scale, height * scale);
            ctx.font = '16px Roboto';
            ctx.fillStyle = '#000000';
            ctx.lineWidth = 1.5;
            ctx.strokeStyle = 'red';

            const defectText = DefectedTypeEnum[item.defectId as keyof typeof DefectedTypeEnum].toString();
            ctx.fillText(defectText, x * scale, y * scale - 5);
            ctx.stroke();
          });
          // Draw Existing Rectangles
          rectangles.forEach((rect) => {
            ctx.strokeStyle = color[rect.number];
            ctx.lineWidth = 2;
            ctx.strokeRect(rect.x, rect.y, rect.width, rect.height);

            // Draw Dimensions if Available
            if (rect.dimensions) {
              ctx.font = '16px Arial';
              ctx.fillStyle = 'blue';

              // Top Edge
              if (rect.dimensions.top) {
                const midX = (rect.cornersCanvas[0].x + rect.cornersCanvas[1].x) / 2;
                const midY = rect.cornersCanvas[0].y - 10;
                ctx.fillText(`${rect.dimensions.top} mm`, midX, midY);
              }

              // Right Edge
              if (rect.dimensions.right) {
                const midX = rect.cornersCanvas[1].x + 10;
                const midY = (rect.cornersCanvas[1].y + rect.cornersCanvas[2].y) / 2;
                ctx.fillText(`${rect.dimensions.right} mm`, midX, midY);
              }

              // Bottom Edge
              if (rect.dimensions.bottom) {
                const midX = (rect.cornersCanvas[2].x + rect.cornersCanvas[3].x) / 2;
                const midY = rect.cornersCanvas[2].y + 20;
                ctx.fillText(`${rect.dimensions.bottom} mm`, midX, midY);
              }

              // Left Edge
              if (rect.dimensions.left) {
                const midX = rect.cornersCanvas[0].x - 30;
                const midY = (rect.cornersCanvas[0].y + rect.cornersCanvas[3].y) / 2;
                ctx.fillText(`${rect.dimensions.left} mm`, midX, midY);
              }
            }

            // Draw A Circle with Number inside at the Start of the Rectangle
            ctx.beginPath();
            ctx.arc(rect.cornersCanvas[0].x - 20, rect.cornersCanvas[0].y, 15, 0, Math.PI * 2);
            ctx.strokeStyle = color[rect.number];
            ctx.lineWidth = 2;
            ctx.stroke();

            ctx.fillStyle = 'black';
            ctx.font = 'bold 16px Arial';
            ctx.textAlign = 'center';
            ctx.textBaseline = 'middle';
            ctx.fillText(rect.number.toString(), rect.cornersCanvas[0].x - 20, rect.cornersCanvas[0].y);
          });

          // Draw Existing Paths
          paths.forEach((path) => {
            ctx.strokeStyle = color[path.number];
            ctx.lineWidth = 2;

            path.segments.forEach((segment, index) => {
              ctx.beginPath();
              ctx.moveTo(segment.startCanvas.x, segment.startCanvas.y);
              ctx.lineTo(segment.endCanvas.x, segment.endCanvas.y);
              ctx.stroke();

              // Draw Distance
              if (segment.distance !== undefined) {
                ctx.font = '16px Arial';
                ctx.fillStyle = 'blue';
                const midX = (segment.startCanvas.x + segment.endCanvas.x) / 2;
                const midY = (segment.startCanvas.y + segment.endCanvas.y) / 2;
                ctx.fillText(`${segment.distance} mm`, midX + 5, midY - 5);
              }
            });

            // Draw A Circle with Number inside at the Start of the Path
            ctx.beginPath();
            ctx.arc(path.segments[0].startCanvas.x - 20, path.segments[0].startCanvas.y, 15, 0, Math.PI * 2);
            ctx.strokeStyle = color[path.number];
            ctx.lineWidth = 2;
            ctx.stroke();

            ctx.fillStyle = 'black';
            ctx.font = 'bold 16px Arial';
            ctx.textAlign = 'center';
            ctx.textBaseline = 'middle';
            ctx.fillText(path.number.toString(), path.segments[0].startCanvas.x - 20, path.segments[0].startCanvas.y);
          });

          // Draw Current Path Segments
          if (currentPath) {
            ctx.strokeStyle = 'orange';
            ctx.lineWidth = 2;

            currentPath.segments.forEach((segment) => {
              ctx.beginPath();
              ctx.moveTo(segment.startCanvas.x, segment.startCanvas.y);
              ctx.lineTo(segment.endCanvas.x, segment.endCanvas.y);
              ctx.stroke();

              // Draw Distance
              if (segment.distance !== undefined) {
                ctx.font = '16px Arial';
                ctx.fillStyle = 'blue';
                const midX = (segment.startCanvas.x + segment.endCanvas.x) / 2;
                const midY = (segment.startCanvas.y + segment.endCanvas.y) / 2;
                ctx.fillText(`${segment.distance} mm`, midX + 5, midY - 5);
              }
            });
          }

          // Draw Temporary Rectangle
          if (tempRect.x !== undefined && tempRect.y !== undefined && tempRect.width !== undefined && tempRect.height !== undefined) {
            ctx.strokeStyle = 'blue';
            ctx.lineWidth = 2;
            ctx.strokeRect(tempRect.x, tempRect.y, tempRect.width, tempRect.height);
          }

          // Draw Temporary Path
          if (pathStartPoint && tempPathPoint) {
            ctx.strokeStyle = 'orange';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(pathStartPoint.x, pathStartPoint.y);
            ctx.lineTo(tempPathPoint.x, tempPathPoint.y);
            ctx.stroke();
          }
        }
      }
    }
  }, [imageLoaded, pavementImage, cracks, rectangles, paths, tempRect, currentPath, tempPathPoint, pathStartPoint, tempRect]);

  // Select path or rectangle tool
  const handleToolSelect = (tool: string) => {
    setCurrentTool(tool);
    setRectClickCount(0);
    setRectStartPoint(null);
    setTempRect({});
    if (tool !== 'path') {
      finalizeCurrentPath();
    }
  };

  // Mouse Move Handler for Temporary Drawing
  const handleMouseMove = (e: React.MouseEvent<HTMLCanvasElement, MouseEvent>) => {
    const canvas = canvasRef.current;
    const img = imageRef.current;
    if (!canvas || !img || !currentTool) return;

    const rect = canvas.getBoundingClientRect();
    const scaleX = img.width / rect.width;
    const scaleY = img.height / rect.height;
    const x = (e.clientX - rect.left) * scaleX;
    const y = (e.clientY - rect.top) * scaleY;

    if (currentTool === 'rectangle') {
      // Rectangle Tool Temporary Drawing
      if (rectClickCount === 1 && rectStartPoint) {
        setTempRect({
          x: rectStartPoint.x,
          y: rectStartPoint.y,
          width: x - rectStartPoint.x,
          height: y - rectStartPoint.y
        });
      }
    } else if (currentTool === 'path') {
      // Path Tool Temporary Drawing
      if (pathStartPoint) {
        setTempPathPoint({ x, y });
      }
    }
  };

  const handleClearLastShape = () => {
    if (currentTool === 'rectangle') {
      if (rectangles.length > 0) {
        setRectangles(rectangles.slice(0, -1));
        setShapeNumber(shapeNumber - 1);
        setTotalDistance(totalDistance.slice(0, -1));
        setRectClickCount(0);
        setRectStartPoint(null);
        setTempRect({});
      }
    } else if (currentTool === 'path') {
      if (paths.length > 0) {
        setPaths(paths.slice(0, -1));
        setShapeNumber(shapeNumber - 1);
        setTotalDistance(totalDistance.slice(0, -1));
        setCurrentPath(null);
        setTempPathPoint(null);
        setPathStartPoint(null);
      }
    }
  };

  const handlePixelMeasurementButton = () => {
    setPixelMeasurement(true);
  };

  // Remove all shape in canvas
  const handleCancelPixelMeasurement = () => {
    setPixelMeasurement(false);
    setRectangles([]);
    setPaths([]);
    setTotalDistance([]);
    setShapeNumber(0);
  };

  const {
    handleSubmit,
    control,
    formState: { errors }
  } = useForm<IFormInput>();
  if (!detectedLocationDetail?.detectedImages) {
    return null;
  }

  const imageCamOneUrl = detectedLocationDetail?.detectedImages[0]?.cameraOneImage?.url || '';
  const imageCamTwoUrl = detectedLocationDetail?.detectedImages[0]?.cameraTwoImage?.url || '';
  const imageCamThreeUrl = detectedLocationDetail?.detectedImages[0]?.cameraThreeImage?.url || '';
  const imageCamFourUrl = detectedLocationDetail?.detectedImages[0]?.cameraFourImage?.url || '';

  const reloadPavementImage = (data: IFormInput) => {
    const newCracks = nmsAlgorithm(detectedLocationDetail?.cracks || [], data.threshIou);
    setCracks(newCracks);
  };
  const reloadPavementImageDefault = () => {
    dispatch(
      getDetectedLocationDetail({
        id: detectedLocationDetail.id
      })
    );
  };
  const query = new URLSearchParams({
    perPage: searchParams?.perPage?.toString() || '10',
    page: searchParams?.page?.toString() || '1',
    orderBy: searchParams?.orderBy || '',
    order: searchParams?.order || 'desc',
    fromDate: searchParams?.fromDate || '',
    toDate: searchParams?.toDate || '',
    fromTime: searchParams?.fromTime || '',
    toTime: searchParams?.toTime || '',
    triggerType: searchParams?.triggerType || '',
    latitude: searchParams?.latitude?.toString() || '',
    longitude: searchParams?.longitude?.toString() || '',
    radius: searchParams?.radius?.toString() || '',
    isCrackDetected: searchParams?.isCrackDetected?.toString() || '',
    selectFolder: searchParams?.selectFolder || ''
  }).toString();

  // ********** Read More Button **********
  const optionsOrientation = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top' as const
      },
      title: {
        display: true,
        text: 'Roll Pitch & Yaw Graph Orientation'
      }
    },
    interaction: {
      mode: 'index' as const,
      intersect: false
    },
    stacked: false,
    scales: {
      x: {
        display: true,
        title: {
          display: true,
          text: 'Index'
        }
      },
      y: {
        display: true,
        title: {
          display: true,
          text: 'Angle (degrees)'
        }
      }
    }
  };

  const optionsAcceleration = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top' as const
      },
      title: {
        display: true,
        text: 'Roll Pitch & Yaw Graph Acceleration'
      }
    },
    interaction: {
      mode: 'index' as const,
      intersect: false
    },
    stacked: false,
    scales: {
      x: {
        display: true,
        title: {
          display: true,
          text: 'Index'
        }
      },
      y: {
        display: true,
        title: {
          display: true,
          text: 'Angle (degrees)'
        }
      }
    }
  };

  const labelLength =
    detectedLocationDetail?.extendData?.orientation_yaw_z?.length &&
    detectedLocationDetail?.extendData?.orientation_yaw_z?.length &&
    detectedLocationDetail?.extendData?.orientation_roll_x?.length
      ? Math.max(
          detectedLocationDetail?.extendData?.orientation_yaw_z?.length,
          detectedLocationDetail?.extendData?.orientation_pitch_y?.length,
          detectedLocationDetail?.extendData?.orientation_roll_x?.length
        )
      : 10;

  const dataOrientation = {
    labels: _.range(labelLength),
    datasets: [
      {
        label: 'Roll (X)',
        data: detectedLocationDetail?.extendData?.orientation_roll_x
      },
      {
        label: 'Pitch (Y)',
        data: detectedLocationDetail?.extendData?.orientation_pitch_y
      },
      {
        label: 'Yaw (Z)',
        data: detectedLocationDetail?.extendData?.orientation_yaw_z
      }
    ]
  };

  const dataAcceleration = {
    labels: _.range(labelLength),
    datasets: [
      {
        label: 'Roll (X)',
        data: detectedLocationDetail?.extendData?.acceleration_x
      },
      {
        label: 'Pitch (Y)',
        data: detectedLocationDetail?.extendData?.acceleration_y
      },
      {
        label: 'Yaw (Z)',
        data: detectedLocationDetail?.extendData?.acceleration_z
      }
    ]
  };

  const hashDefectId = (data: number) => {
    const obj = {
      1: 'Footpath crack',
      2: 'Tactile tile crack',
      3: 'Tactile tile missing',
      4: 'Tile crack',
      5: 'Tile missing',
      6: 'Grating damaged',
      7: 'Grating missing',
      8: 'Footpath uneven',
      9: 'Grating uneven'
    } as any;
    return obj[data];
  };

  const handleProcessed = (data: any) => {
    setProcessed(data);
    return data;
  };
  const handleFetchDataPdf = () => {
    console.log('handle fetch data pdf');
  };

  const totalDistanceArray = Object.values(totalDistance);
  const firstHalf = totalDistanceArray.length > 5 ? totalDistanceArray.slice(0, 5) : totalDistanceArray;
  const secondHalf = totalDistanceArray.length > 5 ? totalDistanceArray.slice(5) : [];

  const calculateArea = (box: any) => {
    return Math.abs(box.boxTopLeftX - box.boxBottomRightX) * Math.abs(box.boxTopLeftY - box.boxBottomRightY);
  };

  const calculateIntersectionOverUnion = (boxLhs: any, boxRhs: any) => {
    const areaLhs = calculateArea(boxLhs);
    const areaRhs = calculateArea(boxRhs);

    // Determine the coordinates of the intersection box
    const x1Inter = Math.max(boxLhs.boxTopLeftX, boxRhs.boxTopLeftX);
    const y1Inter = Math.max(boxLhs.boxTopLeftY, boxRhs.boxTopLeftY);
    const x2Inter = Math.min(boxLhs.boxBottomRightX, boxRhs.boxBottomRightX);
    const y2Inter = Math.min(boxLhs.boxBottomRightY, boxRhs.boxBottomRightY);

    // Determine if the boxes overlap or not
    // If one of the two is equal to 0, the boxes do not overlap
    const interW = Math.max(0, x2Inter - x1Inter);
    const interH = Math.max(0, y2Inter - y1Inter);

    if (interW === 0 || interH === 0) {
      return 0;
    }

    const intersectionArea = interW * interH;
    const unionArea = areaLhs + areaRhs - intersectionArea;

    const iou = intersectionArea / unionArea;
    return iou;
  };

  const nmsAlgorithm = (boxes: any[], iouThreshold: number) => {
    const keptBoxes: any[] = [];
    let copyBoxes = [...boxes];
    while (copyBoxes.length > 0) {
      const box = copyBoxes.shift();
      keptBoxes.push(box);

      copyBoxes = copyBoxes.filter((b) => {
        const iou = calculateIntersectionOverUnion(box, b);
        const isOverlapping = iou >= iouThreshold;

        if (isOverlapping) {
          console.log(`Delete box ${b.id}. Overlapping with ${box.id}.`);
        }

        return !isOverlapping;
      });
    }

    return keptBoxes;
  };

  const handleHotspot1Click = () => {
    if (next) {
      history.pushState(null, '', `/detected-locations/${next}`);
      window.location.reload();
    } else {
      messageApi.warning('No next detected location');
    }
  };

  const handleHotspot2Click = () => {
    if (previous) {
      history.pushState(null, '', `/detected-locations/${previous}`);
      window.location.reload();
    } else {
      messageApi.warning('No previous detected location');
    }
  };

  return (
    <>
      {contextHolder}
      <Container>
        <PdfInformationFormModal
          onData={handleProcessed}
          handleFetchDataPdf={handleFetchDataPdf}
          pixelData={{ pixelMeasurement: [], lengthMeasurement: 1 }}></PdfInformationFormModal>
        <Row className="mt-1">{processed > 0 ? <Progress percent={processed} size="small" /> : null}</Row>
        <Row className="mt-3">
          <Col>
            <Col lg="12">
              <Breadcrumb>
                <Breadcrumb.Item linkAs="div">
                  <NavLink to={`/detected-locations/?${query}`} id="RouterNavLink">
                    Detected location
                  </NavLink>
                </Breadcrumb.Item>

                <Breadcrumb.Item active>{detectedLocationDetail?.folderName}</Breadcrumb.Item>
              </Breadcrumb>
            </Col>
          </Col>
        </Row>
        <Row className="mt-1">
          <Col lg="2">
            <Form.Group className="mb-3">
              <Form.Label className="form-label">Datetime</Form.Label>
              <ReactDatePicker
                className="form-control"
                customInput={<input type="text" readOnly />}
                placeholderText="dd/mm/yyyy "
                onChange={(date) => {
                  // console.log(date);
                }}
                dateFormat="dd/mm/yyyy hh:mm"
                value={date ? date.toFormat('dd/MM/yyyy HH:mm') : undefined}
                readOnly
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label className="form-label">Latitude</Form.Label>
              <Form.Control type="number" id="inputLatitude" min="0" value={latitude} readOnly />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label className="form-label">Longitude</Form.Label>
              <Form.Control type="number" id="inpuLongtitude" min="0" value={longitude} readOnly />
            </Form.Group>
          </Col>
          <Col lg="2">
            <Form.Group className="mb-3">
              <Form.Label className="form-label">Status</Form.Label>
              <StatusColumn rowData={detectedLocationDetail} />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label className="form-label">Last modified</Form.Label>
              <Form.Control type="text" value={format(new Date(detectedLocationDetail.updatedAt), 'dd/MM/yyyy HH:mm:ss')} readOnly />
            </Form.Group>
          </Col>
          <Col lg="2" style={{ marginRight: '-93px' }}>
            <Form.Group className="mb-3">
              <Button variant="outline-secondary" id="button-addon2" onClick={handleShow} style={{ marginTop: '32px' }}>
                Read more
              </Button>
            </Form.Group>
            <Form.Group className="mb-3">
              {getUrl ? (
                <a href={getUrl} target="_blank" rel="noopener noreferrer">
                  <LuFileInput size={18} />
                </a>
              ) : null}
            </Form.Group>
          </Col>
          <Col lg="2" style={{ marginRight: '-93px' }}>
            <Button
              variant="outline-secondary"
              id="button-addon2"
              onClick={() => {
                dispatch(toggleShowExportPdfModal(true));
                id &&
                  dispatch(getNumberDefectIds({ id: id })).then((res) => {
                    const payload = res.payload as { defectType: number };
                    if (payload) {
                      dispatch(setNumberDefectIds(payload.defectType));
                    }
                  });
              }}
              style={{ marginTop: '32px' }}
              disabled={isDownloadLoading}>
              Export PDF
            </Button>
          </Col>
          <Col>
            <MapContainer
              center={[detectedLocationDetail.latitude, detectedLocationDetail.longitude]}
              zoom={13}
              scrollWheelZoom={true}
              style={{ height: '360px' }}>
              <TileLayer
                url="https://www.onemap.gov.sg/maps/tiles/Default/{z}/{x}/{y}.png"
                attribution='<img src="https://www.onemap.gov.sg/web-assets/images/logo/om_logo.png" style="height:20px;width:20px;"/>&nbsp;<a href="https://www.onemap.gov.sg/" target="_blank" rel="noopener noreferrer">OneMap</a>&nbsp;&copy;&nbsp;contributors&nbsp;&#124;&nbsp;<a href="https://www.sla.gov.sg/" target="_blank" rel="noopener noreferrer">Singapore Land Authority</a>'
                maxZoom={25}
                maxNativeZoom={19}
              />
              <Marker position={[detectedLocationDetail.latitude, detectedLocationDetail.longitude]} icon={SelectedLocation}>
                <TooltipLeaflet direction="bottom" opacity={1} permanent>
                  {detectedLocationDetail ? <span>{detectedLocationDetail.roadName || ''}</span> : null}
                </TooltipLeaflet>
              </Marker>
            </MapContainer>
          </Col>
        </Row>

        <Row>
          <Col lg="12">
            <Card body>
              <Card.Title>
                <Row>
                  <Col lg={2}> Pavement Image </Col>

                  <Col lg={pavementImage === 'view' ? 10 : 8} style={{ display: 'flex', justifyContent: 'space-between' }}>
                    {pavementImage === 'view' && (
                      <Button
                        variant="outline-secondary"
                        id="button-addon2"
                        style={{ height: '38px' }}
                        onClick={() => {
                          if (userProfile?.type !== 0) {
                            // if (!drawRectangleImageNone) {
                            //   dispatch(getDrawRectangleImageById({ detectedLocationId: id as any }));
                            // }
                            setPavementImage('edit');
                          } else {
                            setShowAlert(true);
                            setTimeout(() => setShowAlert(false), 2000);
                          }
                        }}>
                        Edit
                      </Button>
                    )}
                    {pavementImage === 'view' ? (
                      totalDistance['0'] ? (
                        <>
                          <div
                            style={{
                              fontSize: '16px',
                              fontWeight: 'normal',
                              lineHeight: 2
                            }}>
                            <table className="table-measurement">
                              <tbody>
                                <tr>
                                  <th>Measurement</th>
                                  <th>Total Distance</th>
                                </tr>
                                {firstHalf.map((distance, index) => (
                                  <tr key={index} style={{ color: 'black' }}>
                                    <th>{index + 1}</th>
                                    <th>{totalDistance[index]} mm</th>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                          {totalDistanceArray.length > 5 && (
                            <div
                              style={{
                                fontSize: '16px',
                                fontWeight: 'normal',
                                lineHeight: 2
                              }}>
                              <table className="table-measurement">
                                <thead>
                                  <tr>
                                    <th>Measurement</th>
                                    <th>Total Distance</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {secondHalf.map((distance, index) => (
                                    <tr key={index} style={{ color: 'black' }}>
                                      <td>{index + 6}</td>
                                      <th>{totalDistance[index + 5]} mm</th>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          )}
                        </>
                      ) : null
                    ) : (
                      ''
                    )}

                    {pavementImage === 'view' ? (
                      !pixelMeasurement ? (
                        <Button style={{ marginLeft: 'auto' }} variant="outline-secondary" id="button-addon3" onClick={handlePixelMeasurementButton}>
                          Pixel Measurement
                        </Button>
                      ) : (
                        <div>
                          <Row>
                            <Col>
                              {(currentTool === 'rectangle' || currentTool === null) && (
                                <Button
                                  style={{ marginRight: '10px' }}
                                  variant="outline-secondary"
                                  id="button-addon3"
                                  onClick={() => handleToolSelect('path')}>
                                  Path
                                </Button>
                              )}

                              {((currentTool === 'path' && !currentPath) || currentTool === null) && (
                                <Button
                                  variant="outline-secondary"
                                  id="button-addon3"
                                  style={{ marginRight: '10px' }}
                                  onClick={() => handleToolSelect('rectangle')}>
                                  Rectangle
                                </Button>
                              )}

                              {currentTool === 'path' && currentPath && (
                                <Button onClick={finalizeCurrentPath} style={{ marginRight: '10px' }} variant="outline-secondary" id="button-addon3">
                                  End Path
                                </Button>
                              )}
                              {!tempPathPoint && (
                                <Button style={{ marginRight: '10px' }} variant="outline-secondary" id="button-addon3" onClick={handleClearLastShape}>
                                  Clear
                                </Button>
                              )}
                              <Button variant="outline-secondary" id="button-addon3" onClick={handleCancelPixelMeasurement}>
                                Cancel
                              </Button>
                            </Col>
                          </Row>
                        </div>
                      )
                    ) : (
                      ''
                    )}

                    {showAlert && (
                      <Modal show={showAlert}>
                        <Modal.Header closeButton>
                          <Modal.Title>Permission</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>You do not have permission to use this feature!</Modal.Body>
                      </Modal>
                    )}
                    {(pavementImage === 'edit' || pavementImage === 'delete') && (
                      <Button
                        variant="outline-secondary"
                        id="button-addon2"
                        onClick={() => {
                          for (let i = 0; i < 100; i++) {
                            if (!sizePosition[i]) {
                              sizePosition[i] = { x: 0, y: 0, width: 100, height: 100, defectId: 1, confidence: 100 };
                              style[i] = { display: 'flex', border: 'solid 2px red' };
                              setSizePosition({ ...sizePosition });
                              setStyle({ ...style });
                              setItemUndo(
                                itemUndo.concat({
                                  index: i,
                                  item: { x: 0, y: 0, width: 100, height: 100, defectId: 1, confidence: 100, started: true }
                                })
                              );
                              break;
                            }
                          }
                        }}>
                        Add
                      </Button>
                    )}
                    {(pavementImage === 'edit' || pavementImage === 'delete') && (
                      <Form.Select
                        aria-label="Default select example"
                        style={{ width: '50%', marginLeft: '10px' }}
                        defaultValue={1}
                        onChange={(event) => {
                          setSizePosition({ ...sizePosition, [index]: { ...sizePosition[index], defectId: parseInt(event.target.value) } });
                        }}>
                        <option value="1">Footpath crack</option>
                        <option value="2">Tactile tile crack</option>
                        <option value="3">Tactile tile missing</option>
                        <option value="4">Tile crack</option>
                        <option value="5">Tile missing</option>
                        <option value="6">Grating damaged</option>
                        <option value="7">Grating missing</option>
                        <option value="8">Footpath uneven</option>
                        <option value="9">Grating uneven</option>
                      </Form.Select>
                    )}

                    {pavementImage === 'edit' && (
                      <div style={{ display: 'flex' }}>
                        <Button
                          style={{ marginLeft: '10px' }}
                          variant="outline-secondary"
                          id="button-addon2"
                          onClick={() => {
                            const newSizePosition = { ...sizePosition };
                            setItemUndo(itemUndo.concat({ index: index, item: newSizePosition[index] }));
                            delete newSizePosition[index];
                            const newStyle = { ...style };
                            delete newStyle[index];
                            setSizePosition(newSizePosition);
                            setStyle(newStyle);
                          }}>
                          Delete
                        </Button>
                        <Button
                          style={{ marginLeft: '10px' }}
                          variant="outline-secondary"
                          id="button-addon2"
                          onClick={() => {
                            setSizePosition({});
                            setStyle({});
                          }}>
                          Delete All
                        </Button>
                        <Button
                          style={{ marginLeft: '10px' }}
                          variant="outline-secondary"
                          id="button-addon2"
                          disabled={itemUndo.length === 0}
                          onClick={() => {
                            const newSizePosition = { ...sizePosition };
                            const copyItemUndo = [...itemUndo];
                            const data = copyItemUndo[copyItemUndo.length - 1];
                            if (newSizePosition[data.index]) {
                              delete newSizePosition[data.index];
                            }
                            if (!data.item.started) {
                              newSizePosition[data.index] = data.item;
                            }
                            const newStyle = { ...style };
                            if (newStyle[data.index]) {
                              delete newStyle[data.index];
                            }
                            newStyle[data.index] = { display: 'flex', border: 'solid 2px red' };

                            setSizePosition(newSizePosition);
                            setStyle(newStyle);
                            setItemUndo(copyItemUndo.slice(0, -1));
                          }}>
                          Undo
                        </Button>
                      </div>
                    )}
                  </Col>
                  {(pavementImage === 'edit' || pavementImage === 'delete') && (
                    <Col style={{ display: 'flex', justifyContent: 'flex-end' }}>
                      <Button
                        style={{ marginRight: '10px' }}
                        variant="outline-secondary"
                        id="button-addon2"
                        onClick={() => {
                          setPavementImage('view');
                          setStyle({});
                          setSizePosition({});
                        }}>
                        Cancel
                      </Button>
                      <Button
                        variant="outline-secondary"
                        id="button-addon2"
                        onClick={() => {
                          dispatch(
                            updateDetectedData({ id: detectedLocationDetail.id, position: sizePosition, width: WIDTH_IMAGE, height: HEIGHT_IMAGE })
                          ).then((res) => {
                            reloadPavementImageDefault();
                          });
                          setPavementImage('view');
                        }}>
                        Save
                      </Button>
                    </Col>
                  )}
                </Row>
              </Card.Title>

              {pavementImage === 'view' ? (
                <div style={{ cursor: 'pointer' }}>
                  <TransformWrapper
                    initialScale={1}
                    onWheelStop={({ state: { scale } }) => {
                      setScale(scale);
                    }}>
                    {({ zoomIn, zoomOut, resetTransform }) => (
                      <div>
                        <div>
                          <Row className="mb-2 ">
                            <Col lg={3}>
                              <Form onSubmit={handleSubmit(reloadPavementImage)}>
                                <Form.Group className="">
                                  <InputGroup className="">
                                    <Controller
                                      rules={{
                                        max: { value: 1, message: 'Maximum is 1' },
                                        min: { value: 0.1, message: 'Minimum is 0.1' }
                                      }}
                                      control={control}
                                      name="threshIou"
                                      defaultValue={0.8}
                                      render={({ field }) => (
                                        <FormControl {...field} isInvalid={!!errors.threshIou} type="number" min={0.1} max={1} step={0.1} />
                                      )}
                                    />
                                    <Button variant="outline-secondary" id="button-addon2" type="submit" disabled={drawImageLoading}>
                                      {!drawImageLoading ? 'Reload with Thresh Iou' : 'Loading...'}
                                    </Button>

                                    <Form.Control.Feedback type="invalid">{errors.threshIou?.message}</Form.Control.Feedback>
                                  </InputGroup>
                                </Form.Group>
                              </Form>
                            </Col>

                            <Col className="d-flex justify-content-end  align-items-start">
                              <button onClick={() => zoomIn()} className="me-1 btn btn-outline-secondary btn-sm">
                                <BsZoomIn></BsZoomIn>
                              </button>
                              <button onClick={() => zoomOut()} className="me-1 btn btn-outline-secondary btn-sm">
                                <BsZoomOut></BsZoomOut>
                              </button>
                              <button
                                className="btn btn-outline-secondary btn-sm"
                                onClick={() => {
                                  resetTransform();
                                  setScale(1);
                                }}>
                                <BsArrowCounterclockwise></BsArrowCounterclockwise>
                              </button>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <div style={{ height: '100%' }}>
                                <TransformComponent>
                                  {!pixelMeasurement ? (
                                    <div style={{ position: 'relative' }} className="image-container">
                                      <img
                                        ref={imageRef}
                                        style={{ cursor: 'pointer', display: 'none' }}
                                        className="d-block w-100 h-100"
                                        src={drawRectangleImage}
                                        onLoad={() => setImageLoaded(true)}
                                      />
                                      <canvas
                                        ref={canvasRef}
                                        width={WIDTH_IMAGE}
                                        height={HEIGHT_IMAGE}
                                        style={{ position: 'absolute', top: 0, left: 0 }}
                                      />
                                      <div className="hotspot hotspot-1" onClick={handleHotspot1Click}>
                                        <IoIosArrowUp className="icon" />
                                      </div>
                                      <div className="hotspot hotspot-2" onClick={handleHotspot2Click}>
                                        <IoIosArrowDown className="icon" />
                                      </div>
                                    </div>
                                  ) : (
                                    !drawImageLoading && (
                                      <div style={{ position: 'relative', cursor: 'crosshair' }}>
                                        <img ref={imageRef} className="d-block w-100 h-auto" src={drawRectangleImage} />
                                        <canvas
                                          ref={canvasRef}
                                          width={WIDTH_IMAGE}
                                          height={HEIGHT_IMAGE}
                                          onClick={handleCanvasClick}
                                          onMouseMove={handleMouseMove}
                                          style={{ position: 'absolute', top: 0, left: 0 }}
                                        />
                                      </div>
                                    )
                                  )}
                                </TransformComponent>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </div>
                    )}
                  </TransformWrapper>
                </div>
              ) : (
                <div>
                  {!drawImageLoading && (
                    <div style={{ position: 'relative', height: '100%', width: '100%' }}>
                      <img style={{ cursor: 'pointer' }} className="d-block w-100 h-100" src={drawRectangleImage} />
                      {Object.keys(sizePosition).map((item, i) => (
                        <Rnd
                          key={item}
                          style={style[item]}
                          // default={sizePosition[item]}
                          size={{ width: sizePosition[item]['width'], height: sizePosition[item]['height'] }}
                          position={{ x: sizePosition[item]['x'], y: sizePosition[item]['y'] }}
                          bounds="parent"
                          onDragStop={(e, d) => {
                            const newStyle = { ...style };
                            Object.keys(newStyle).map((s, i) => {
                              newStyle[s] = { display: 'flex', border: 'solid 2px red' };
                            });
                            newStyle[item] = { display: 'flex', border: 'solid 2px yellow' };
                            setStyle(newStyle);
                            const newSizePosition = { ...sizePosition };
                            newSizePosition[item]['x'] = d.x;
                            newSizePosition[item]['y'] = d.y;
                            newSizePosition[item]['confidence'] = 100;
                            setSizePosition(newSizePosition);
                            setIndex(parseInt(item));
                          }}
                          onResizeStop={(e, direction, ref, delta, position) => {
                            const newSizePosition = { ...sizePosition };
                            newSizePosition[item]['width'] = ref.style.width;
                            newSizePosition[item]['height'] = ref.style.height;
                            newSizePosition[item]['x'] = position.x;
                            newSizePosition[item]['y'] = position.y;
                            newSizePosition[item]['confidence'] = 100;
                            setSizePosition(newSizePosition);
                          }}
                          onDragStart={(e, data) => {
                            const copyItemUndo = [...itemUndo];
                            setItemUndo(
                              copyItemUndo.concat({
                                index: parseInt(item),
                                item: {
                                  x: data.x,
                                  y: data.y,
                                  width: sizePosition[item]['width'],
                                  height: sizePosition[item]['height'],
                                  defectId: sizePosition[item]['defectId'],
                                  confidence: sizePosition[item]['confidence']
                                }
                              })
                            );
                          }}
                          onResizeStart={(e, direction, ref) => {
                            const transformString = ref.style.transform;
                            const match = transformString.match(/translate\((\d+(\.\d+)?)px, (\d+(\.\d+)?)px\)/);
                            if (match) {
                              setItemUndo(
                                itemUndo.concat({
                                  index: parseInt(item),
                                  item: {
                                    x: parseFloat(match[1]),
                                    y: parseFloat(match[3]),
                                    width: parseFloat(ref.style.width),
                                    height: parseFloat(ref.style.height),
                                    defectId: sizePosition[item]['defectId'],
                                    confidence: sizePosition[item]['confidence']
                                  }
                                })
                              );
                            }
                          }}>
                          <div className="box" style={{ position: 'relative', top: '-25px', fontSize: '15px', fontWeight: '500' }}>
                            {hashDefectId(sizePosition[item]['defectId'])}
                          </div>
                        </Rnd>
                      ))}
                    </div>
                  )}
                </div>
              )}

              {drawImageLoading && (
                <div className="d-flex justify-content-center">
                  <Spinner variant="primary"></Spinner>
                </div>
              )}
            </Card>
          </Col>
        </Row>
        <Row className="">
          <Col lg="12" className="mt-2">
            <Card body>
              <Card.Title>Panorama Image</Card.Title>
              <div className="overflow-auto">
                <img src={detectedLocationDetail.detectedImages[0]?.panoImage?.url}></img>
              </div>
            </Card>
          </Col>
        </Row>
        <Row className="">
          <Col lg="12" className="mt-2">
            <Card body>
              <Card.Title>Equirec Image</Card.Title>
              {getPanoLoading && (
                <div className="d-flex justify-content-center">
                  <Spinner variant="primary"></Spinner>
                </div>
              )}
              {panoImage && (
                <Pannellum
                  Pannellum
                  width="100%"
                  height="500px"
                  image={addPrefixToImageBase64(panoImage, 'data:image/jpg;base64,')}
                  haov={360}
                  yaw={180}
                  vaov={250}
                  vOffset={0}
                  pitch={0}
                  hfov={120}
                  maxHfov={120}
                  minHfov={100}
                  autoLoad>
                  <Pannellum.Hotspot
                    type="custom"
                    pitch={yawPitchNext ? parseInt(yawPitchNext.pitch) : 15}
                    yaw={yawPitchNext ? parseInt(yawPitchNext.yaw) : 180}
                    text="20210427_101350_645"
                    handleClick={() => {
                      if (next) {
                        history.pushState(null, '', `/detected-locations/${next}`);
                        window.location.reload();
                      }
                    }}
                  />
                  <Pannellum.Hotspot
                    type="custom"
                    pitch={yawPitchPrevious?.pitch || -10}
                    yaw={yawPitchPrevious?.yaw || -1}
                    text="20210427_101350_645"
                    handleClick={() => {
                      if (previous) {
                        history.pushState(null, '', `/detected-locations/${previous}`);
                        window.location.reload();
                      }
                    }}
                  />
                </Pannellum>
              )}
              {/* <Card.Img variant="top" src={} /> */}
            </Card>
          </Col>
        </Row>
        <Row className="mt-2">
          <Col lg="12">
            <Card body>
              <Carousel>
                <Carousel.Item>
                  <img className="d-block w-100" src={imageCamOneUrl} />

                  <Carousel.Caption>
                    <h3> Right Camera </h3>
                  </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                  <img className="d-block w-100" src={imageCamTwoUrl} />

                  <Carousel.Caption>
                    <h3> Back Camera </h3>
                  </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                  <img className="d-block w-100" src={imageCamThreeUrl} />

                  <Carousel.Caption>
                    <h3> Left Camera </h3>
                  </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                  <img className="d-block w-100" src={imageCamFourUrl} />

                  <Carousel.Caption>
                    <h3> Front Camera </h3>
                  </Carousel.Caption>
                </Carousel.Item>
              </Carousel>
            </Card>
          </Col>
        </Row>
        <Offcanvas show={show} onHide={handleClose} placement={'end'} style={{ width: '900px' }}>
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>Detected location name: {detectedLocationDetail?.folderName}</Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <div className="custom-table">
              <table>
                <tbody>
                  <tr>
                    <th style={{ width: '25%' }}>Sensor Field</th>
                    <th style={{ width: '30%' }}>Units</th>
                    <th style={{ width: '45%' }}>Value</th>
                  </tr>
                  <tr>
                    <td>GNSS Altitude</td>
                    <td>m</td>
                    <td>{detectedLocationDetail?.extendData?.gnss_altitude}</td>
                  </tr>
                  <tr>
                    <td>GNSS Fix Type</td>
                    <td>
                      <span>0: No Fix</span>
                      <br />
                      <span>1: DeadReckoning (N/A)</span>
                      <br />
                      <span>2: 2D (insufficient SIV)</span>
                      <br />
                      <span>3: 3D (standard fix)</span>
                      <br />
                      <span>4: GNSS + DeadReckoning (N/A)</span>
                      <br />
                      <span>5: Time fix only</span>
                      <br />
                      <span>6: RTK-float</span>
                      <br />
                      <span>7: RTK-fixed</span>
                    </td>
                    <td>{detectedLocationDetail?.extendData?.gnss_fix_type}</td>
                  </tr>
                  <tr>
                    <td>GNSS Satellites in View</td>
                    <td>no units</td>
                    <td>{detectedLocationDetail?.extendData?.gnss_satellites_in_view}</td>
                  </tr>
                  <tr>
                    <td>GNSS Accuracy</td>
                    <td>mm</td>
                    <td>{detectedLocationDetail?.extendData?.gnss_accuracy}</td>
                  </tr>
                  <tr>
                    <td>GNSS Ground Seed</td>
                    <td>m/s</td>
                    <td>{detectedLocationDetail?.extendData?.gnss_ground_speed}</td>
                  </tr>
                  <tr>
                    <td>GNSS Heading</td>
                    <td>deg</td>
                    <td>{detectedLocationDetail?.extendData?.gnss_heading}</td>
                  </tr>
                  <tr>
                    <td>GNSS Navigation Rate</td>
                    <td>Hz</td>
                    <td>{detectedLocationDetail?.extendData?.gnss_navigation_rate}</td>
                  </tr>
                  <tr>
                    <td>Input Voltage</td>
                    <td>V</td>
                    <td>{detectedLocationDetail?.extendData?.input_voltage}</td>
                  </tr>
                  <tr>
                    <td>Input Current</td>
                    <td>A</td>
                    <td>{detectedLocationDetail?.extendData?.input_current}</td>
                  </tr>
                  <tr>
                    <td>Battery Voltage</td>
                    <td>V</td>
                    <td>{detectedLocationDetail?.extendData?.batt_voltage}</td>
                  </tr>
                  <tr>
                    <td>Battery Current</td>
                    <td>A</td>
                    <td>{detectedLocationDetail?.extendData?.batt_current}</td>
                  </tr>
                  <tr>
                    <td>PoE Voltage</td>
                    <td>V</td>
                    <td>{detectedLocationDetail?.extendData?.poe_voltage}</td>
                  </tr>
                  <tr>
                    <td>PoE Current</td>
                    <td>A</td>
                    <td>{detectedLocationDetail?.extendData?.poe_current}</td>
                  </tr>
                  <tr>
                    <td>PC Voltage</td>
                    <td>V</td>
                    <td>{detectedLocationDetail?.extendData?.pc_voltage}</td>
                  </tr>
                  <tr>
                    <td>PC Current</td>
                    <td>A</td>
                    <td>{detectedLocationDetail?.extendData?.pc_current}</td>
                  </tr>
                  <tr>
                    <td>State of Charge</td>
                    <td>0 to 100%</td>
                    <td>{detectedLocationDetail?.extendData?.state_of_charge}</td>
                  </tr>
                  <tr>
                    <td>Ambient Light</td>
                    <td>lux</td>
                    <td>{detectedLocationDetail?.extendData?.ambient_light_lux}</td>
                  </tr>
                  <tr>
                    <td>Trigger to Shutter Latency</td>
                    <td>millisecond (ms)</td>
                    <td>{detectedLocationDetail?.extendData?.trigger_to_shutter_latency}</td>
                  </tr>
                  <tr>
                    <td>Camera Exposure</td>
                    <td>microsecond (us)</td>
                    <td>{detectedLocationDetail?.extendData?.camera_exposure?.cam5}</td>
                  </tr>
                  <tr>
                    <td>Camera Gain</td>
                    <td>decibels (dB)</td>
                    <td>{detectedLocationDetail?.extendData?.camera_gain?.cam5}</td>
                  </tr>
                  <tr>
                    <td>Orientation Roll (X)</td>
                    <td>
                      <span>degrees</span>
                      <br />
                      <span>(-90° to +90°)</span>
                    </td>
                    <td>{detectedLocationDetail?.extendData?.orientation_roll_x?.join(', ')}</td>
                  </tr>
                  <tr>
                    <td>Orientation Pitch (Y)</td>
                    <td>
                      <span>degrees</span>
                      <br />
                      <span>(-90° to +90°)</span>
                    </td>
                    <td>{detectedLocationDetail?.extendData?.orientation_pitch_y?.join(', ')}</td>
                  </tr>
                  <tr>
                    <td>Orientation Yaw (Z)</td>
                    <td>
                      <span>degrees</span>
                      <br />
                      <span>(-180° to +180°)</span>
                    </td>
                    <td>{detectedLocationDetail?.extendData?.orientation_yaw_z?.join(', ')}</td>
                  </tr>
                  <tr>
                    <td>Acceleration Roll (X)</td>
                    <td>
                      <span>degrees</span>
                      <br />
                      <span>(-90° to +90°)</span>
                    </td>
                    <td>{detectedLocationDetail?.extendData?.acceleration_x?.join(', ')}</td>
                  </tr>
                  <tr>
                    <td>Acceleration Pitch (Y)</td>
                    <td>
                      <span>degrees</span>
                      <br />
                      <span>(-90° to +90°)</span>
                    </td>
                    <td>{detectedLocationDetail?.extendData?.acceleration_y?.join(', ')}</td>
                  </tr>
                  <tr>
                    <td>Acceleration Yaw (Z)</td>
                    <td>
                      <span>degrees</span>
                      <br />
                      <span>(-180° to +180°)</span>
                    </td>
                    <td>{detectedLocationDetail?.extendData?.acceleration_z?.join(', ')}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <br />
            <Line options={optionsOrientation} data={dataOrientation} />
            <Line options={optionsAcceleration} data={dataAcceleration} />
          </Offcanvas.Body>
        </Offcanvas>
      </Container>
    </>
  );
};

export default DetectedLocationDetail;
