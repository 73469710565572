import React, { useState } from 'react';
import { Form, Button, FormControl, InputGroup, Container, Row, Col } from 'react-bootstrap';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { userRegister } from '../../authApi';
import { selectLoading, selectRegisterError } from '../../authSlice';
import { registerI } from '../../authType';
import { NavLink, useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContent } from 'react-toastify/dist/types';

// interface RegisterProps {}

const Register: React.FC = () => {
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const dispatch = useAppDispatch();
  const isLoading = useAppSelector(selectLoading);
  const navigate = useNavigate();
  const registerError = useAppSelector(selectRegisterError);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const payload: registerI = { firstName, lastName, email, password };
    if (!firstName || !lastName || !email || !password) {
      return toast.error('Please enter all information', {
        position: 'top-center',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light'
      });
    }
    const res = await dispatch(userRegister(payload));
    if (res.meta.requestStatus == 'fulfilled') {
      return navigate('/login');
    } else if (res.meta.requestStatus == 'rejected') {
      toast.error(registerError as ToastContent, {
        position: 'top-center',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light'
      });
    }
  };
  return (
    <>
      <Container className="position-absolute top-50 start-50 translate-middle">
        <Row className="justify-content-center">
          <Col md="5" className="shadow p-5 mb-5 bg-body rounded rounded-4">
            <Row>
              <Col className="mb-4 d-flex justify-content-center" lg="12">
                <div className="fs-1">PathWatcher</div>
              </Col>
              <Col className="mb-1 d-flex justify-content-center" lg="12">
                <div className="fs-4">Create your account</div>
              </Col>
              <Col className="mb-3 d-flex justify-content-center" lg="12">
                <div className="fs-6 text-secondary text-opacity-50">
                  Please enter your details.
                </div>
              </Col>
            </Row>

            <Form onSubmit={handleSubmit} autoComplete="off">
              <Form.Group controlId="formBasicFirstName" className="mb-3">
                <Form.Label>First Name</Form.Label>
                <FormControl
                  type="text"
                  placeholder="Enter first name"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  size="lg"
                />
              </Form.Group>
              <Form.Group controlId="formBasicLastName" className="mb-3">
                <Form.Label>Last Name</Form.Label>
                <FormControl
                  type="text"
                  placeholder="Enter last name"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  size="lg"
                />
              </Form.Group>
              <Form.Group controlId="formBasicEmail" className="mb-3">
                <Form.Label>Email</Form.Label>
                <FormControl
                  type="email"
                  placeholder="Enter email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  size="lg"
                />
              </Form.Group>
              <Form.Group controlId="formBasicPassword" className="mb-3">
                <Form.Label>Password</Form.Label>
                <InputGroup className="mb-3">
                  <FormControl
                    type="password"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    size="lg"
                  />
                </InputGroup>
              </Form.Group>
              <div className="d-grid gap-2 justify-content-center mb-2">
                <NavLink className={' text-decoration-none'} to={'/login'}>
                  Back to Login?
                </NavLink>
              </div>
              <div className="d-grid gap-2">
                <Button disabled={isLoading} variant="primary" size="lg" type="submit">
                  {isLoading ? 'Loading…' : 'Register'}
                </Button>
              </div>
            </Form>
          </Col>
        </Row>
      </Container>
      <ToastContainer />
    </>
  );
};
export default Register;
