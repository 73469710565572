import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import {
  getUserProfile,
  updatePassword,
  updateUserProfile,
  userLogin,
  userLogout,
  userRegister,
  updateTelegramInfo,
  getTelegramInfo,
  unlinkTelegramInfo
} from './authApi';
import { AuthState, ErrorI, UserProfile } from './authType';

const userInfo = localStorage.getItem('userProfile') ? (JSON.parse(localStorage.getItem('userProfile') || '') as UserProfile) : null;

const initialState: AuthState = {
  userInfo: userInfo,
  error: null,
  success: false,
  isShowModal: false,
  changePasswordError: null,
  changePasswordLoading: false,
  type: 0,
  telegramInfo: null
};

export const selectLoading = (state: RootState) => state.auth.loading;
export const selectTelegramInfo = (state: RootState) => state.auth.telegramInfo;
export const selectUserProfile = (state: RootState) => state.auth.userInfo;
export const selectAuth = (state: RootState) => state.auth;
export const selectIslogined = (state: RootState) => state.auth.userInfo;
export const selectRegisterError = (state: RootState) => state.auth.error;
export const selectLoginError = (state: RootState) => state.auth.error;
export const selectIsShowModal = (state: RootState) => state.auth.isShowModal;
export const selectChangePasswordLoading = (state: RootState) => state.auth.changePasswordLoading;

const loginSlice = createSlice({
  name: 'auth',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(userLogin.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(userLogin.fulfilled, (state, action) => {
      state.loading = false;
      // state.userToken = action.payload.access_token;
      state.userInfo = action.payload;
    });
    builder.addCase(userLogin.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
    builder.addCase(getUserProfile.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getUserProfile.fulfilled, (state, action) => {
      state.loading = false;
      state.userInfo = action.payload;
    });
    builder.addCase(getUserProfile.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.userToken = null;
      localStorage.removeItem('userProfile');
    });
    builder.addCase(userRegister.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(userRegister.fulfilled, (state) => {
      state.loading = false;
      // state.userInfo = action.payload;
    });
    builder.addCase(userRegister.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
      // state.userToken = null;
    });
    builder.addCase(userLogout.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(userLogout.fulfilled, (state) => {
      state.loading = false;
      localStorage.removeItem('userProfile');
      state.userInfo = null;
      state.error = null;
      state.success = false; // state.userInfo = action.payload;
    });
    builder.addCase(userLogout.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
      // state.userToken = null;
    });
    builder.addCase(updateUserProfile.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(updateUserProfile.fulfilled, (state, action) => {
      state.loading = false;
      state.userInfo = action.payload;
    });
    builder.addCase(updateUserProfile.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
      // state.userToken = null;
    });
    builder.addCase(updatePassword.pending, (state) => {
      state.changePasswordLoading = true;
      state.changePasswordError = null;
    });
    builder.addCase(updatePassword.fulfilled, (state) => {
      state.changePasswordLoading = false;
    });
    builder.addCase(updatePassword.rejected, (state, action) => {
      state.changePasswordLoading = false;
      state.changePasswordError = action.payload as ErrorI;
      // state.userToken = null;
    });
    builder.addCase(updateTelegramInfo.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(updateTelegramInfo.fulfilled, (state, action) => {
      state.loading = false;
      state.telegramInfo = action.payload;
    });
    builder.addCase(updateTelegramInfo.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.telegramInfo = null;
    });
    builder.addCase(getTelegramInfo.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getTelegramInfo.fulfilled, (state, action) => {
      state.loading = false;
      state.telegramInfo = action.payload;
    });
    builder.addCase(getTelegramInfo.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.telegramInfo = null;
    });
    builder.addCase(unlinkTelegramInfo.fulfilled, (state, action) => {
      state.loading = false;
      state.telegramInfo = null;
    });
  },
  reducers: {
    logout: (state) => {
      if (state.userToken) {
        state.userToken = null;
        localStorage.removeItem('userProfile');
        state.userInfo = null;
        state.error = null;
        state.success = false;
      }
    },
    toggleShowModal: (state, action) => {
      state.isShowModal = action.payload;
    }
  }
});
export const { logout, toggleShowModal } = loginSlice.actions;

export default loginSlice.reducer;
