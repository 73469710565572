import React from 'react';
import 'bootstrap-icons/font/bootstrap-icons.css';
import Login from '../../features/auth/components/Core/Login';
import BackgroundLogin from '../../Assets/images/login.png';
import { Container } from 'react-bootstrap';
import './Login.scss';
import { useSelector } from 'react-redux';
import { selectIslogined } from '../../features/auth/authSlice';
import { Navigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

function LoginPage() {
  const isAuthenticated = useSelector(selectIslogined);
  if (isAuthenticated) {
    return <Navigate to="/detected-locations" replace={true} />;
  }
  return (
    <Container className="d-flex">
      <Login></Login>
      <img
        src={BackgroundLogin}
        alt="background login"
        className="position-absolute background-login h-100 w-50 top-0"
      />
      <ToastContainer />
    </Container>
  );
}

export default LoginPage;
