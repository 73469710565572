import { createAsyncThunk } from '@reduxjs/toolkit';
import instance from '../../Axios';
import { RootState } from '../../app/store';
import authInstance from '../../Axios/authInstance';
import { registerI, UpdatePassword, UpdateProfile, UserProfile } from './authType';

export interface loginI {
  email: string;
  password: string;
}
export interface forgotPasswordI {
  email: string;
}

// authActions.js
export const userLogin = createAsyncThunk('auth/login', async ({ email, password }: loginI, { rejectWithValue }) => {
  try {
    // configure header's Content-Type as JSON
    const config = {
      headers: {
        'Content-Type': 'application/json'
      },
      withCredentials: true
    };
    const { data } = await instance.post(`/api/auth/log-in`, { email: email, password }, config);
    // store user's token in local storage
    localStorage.setItem('userProfile', JSON.stringify(data));
    return data; // eslint-disable-next-line
  } catch (error: any) {
    // return custom error message from API if any
    if (error.response && error.response.data.message) {
      return rejectWithValue(error.response.data.message);
    } else {
      return rejectWithValue(error.message);
    }
  }
});

export const userForgotPassword = createAsyncThunk('auth/forgotPassword', async ({ email }: forgotPasswordI, { rejectWithValue }) => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };
    const { data } = await instance.post(`/api/auth/forgot-password`, { email }, config);
    return data;
  } catch (error: any) {
    if (error.response && error.response.data.message) {
      return rejectWithValue(error.response.data.message);
    } else {
      return rejectWithValue(error.message);
    }
  }
});

export const getUserProfile = createAsyncThunk<UserProfile, void, { state: RootState }>('auth/getUserProfile', async (_, { rejectWithValue }) => {
  try {
    const { data } = await authInstance.get(`/api/auth/profile`);
    return data;
    // eslint-disable-next-line
  } catch (error: any) {
    // return custom error message from API if any
    if (error.response && error.response.data.message) {
      return rejectWithValue(error.response.data.message);
    } else {
      return rejectWithValue(error.message);
    }
  }
});

export const getAllUsers = createAsyncThunk<UserProfile[], void, { state: RootState }>('auth/getAllUsers', async (_, { rejectWithValue }) => {
  try {
    const { data } = await instance.get(`/api/users`);
    return data;
    // eslint-disable-next-line
  } catch (error: any) {
    // return custom error message from API if any
    if (error.response && error.response.data.message) {
      return rejectWithValue(error.response.data.message);
    } else {
      return rejectWithValue(error.message);
    }
  }
});

export const updateCredentialUser = createAsyncThunk<UserProfile[], { userId: string; isActive?: boolean; type?: number }, { state: RootState }>(
  'users/credential',
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await instance.put(`/api/users/credential`, payload);
      return data;
      // eslint-disable-next-line
    } catch (error: any) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const userRegister = createAsyncThunk<UserProfile, registerI, { state: RootState }>(
  'auth/userRegister',
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await instance.post(`/api/auth/register`, payload);
      return data;
      // eslint-disable-next-line
    } catch (error: any) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
export const userLogout = createAsyncThunk('auth/userLogout', async (_, { rejectWithValue }) => {
  try {
    await authInstance.post(`/api/auth/log-out`);
    // eslint-disable-next-line
  } catch (error: any) {
    // return custom error message from API if any
    if (error.response && error.response.data.message) {
      return rejectWithValue(error.response.data.message);
    } else {
      return rejectWithValue(error.message);
    }
  }
});
export const updateUserProfile = createAsyncThunk<UserProfile, UpdateProfile, { state: RootState }>(
  'user/updateUserProfile',
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await instance.put(`/api/users/profile`, payload);
      return data;
      // eslint-disable-next-line
    } catch (error: any) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const updatePassword = createAsyncThunk<UserProfile, UpdatePassword, { state: RootState }>(
  'user/updatePassword',
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await instance.put(`/api/auth/update-password`, payload);
      return data;
      // eslint-disable-next-line
    } catch (error: any) {
      // return custom error message from API if any
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const updateTelegramInfo = createAsyncThunk<UserProfile, { token: string }, { state: RootState }>(
  'user/updateTelegramInfo',
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await instance.post(`/api/auth/link-telegram-integration`, payload);
      return data;
      // eslint-disable-next-line
    } catch (error: any) {
      // return custom error message from API if any
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getTelegramInfo = createAsyncThunk<UserProfile, void, { state: RootState }>(
  'user/getTelegramInfo',
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await instance.get(`/api/auth/integration`);
      return data;
      // eslint-disable-next-line
    } catch (error: any) {
      // return custom error message from API if any
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const checkTelegramIntegration = createAsyncThunk<UserProfile, { token: string }, { state: RootState }>(
  'user/checkTelegramIntegration',
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await instance.post(`/api/auth/test-telegram-integration`, payload);
      return data;
      // eslint-disable-next-line
    } catch (error: any) {
      // return custom error message from API if any
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const unlinkTelegramInfo = createAsyncThunk<UserProfile, void, { state: RootState }>(
  'user/unlinkTelegramInfo',
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await instance.delete(`/api/auth/unlink-telegram-integration`);
      return data;
      // eslint-disable-next-line
    } catch (error: any) {
      // return custom error message from API if any
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
