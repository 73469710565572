export const listRegexFile = [
  /^PavementMask_\d{8}_\d{6}_\d{3}(_[A-Z])?\.(txt)$/,
  /^Sensors_\d{8}_\d{6}_\d{3}(_[A-Z])?\.(txt|json)$/,
  /^Cam1_\d{8}_\d{6}_\d{3}(_[A-Z])?\.(jpg|png|jpeg)$/,
  /^Cam2_\d{8}_\d{6}_\d{3}(_[A-Z])?\.(jpg|png|jpeg)$/,
  /^Cam3_\d{8}_\d{6}_\d{3}(_[A-Z])?\.(jpg|png|jpeg)$/,
  /^Cam4_\d{8}_\d{6}_\d{3}(_[A-Z])?\.(jpg|png|jpeg)$/,
  /^Cam5_\d{8}_\d{6}_\d{3}(_[A-Z])?\.(jpg|png|jpeg)$/,
  /^Cam5_\d{8}_\d{6}_\d{3}(_[A-Z])?_Corr\.(jpg|png|jpeg)$/,
  /^Pano360_\d{8}_\d{6}_\d{3}(_[A-Z])?\.(jpg|png|jpeg)$/,
  /^Equirec_\d{8}_\d{6}_\d{3}(_[A-Z])?\.(jpg|png|jpeg)$/,
  /^Depth8_\d{8}_\d{6}_\d{3}(_[A-Z])?\.(jpg|png|jpeg)$/
];

export enum fileUploadPrefix {
  Cam1 = 'Cam1',
  cam2 = 'cam2',
  cam3 = 'cam3',
  cam4 = 'cam4',
  cam5 = 'cam5',
  Pano360 = 'Pano360',
  Depth8 = 'Depth8'
}
export const pavementMaskPattern = /^PavementMask_\d{8}_\d{6}_\d{3}(_[A-Z])?\.txt$/;
export const sensorPatternTxt = /^Sensors_\d{8}_\d{6}_\d{3}(_[A-Z])?\.txt$/;
export const sensorPatternJson = /^Sensors_\d{8}_\d{6}_\d{3}(_[A-Z])?\.json$/;
export const cam1Pattern = /^Cam1_\d{8}_\d{6}_\d{3}(_[A-Z])?\.(jpg|png|jpeg)$/;
export const cam2Pattern = /^Cam2_\d{8}_\d{6}_\d{3}(_[A-Z])?\.(jpg|png|jpeg)$/;
export const cam3Pattern = /^Cam3_\d{8}_\d{6}_\d{3}(_[A-Z])?\.(jpg|png|jpeg)$/;
export const cam4Pattern = /^Cam4_\d{8}_\d{6}_\d{3}(_[A-Z])?\.(jpg|png|jpeg)$/;
export const cam5Pattern = /^Cam5_\d{8}_\d{6}_\d{3}(_[A-Z])?\.(jpg|png|jpeg)$/;
export const cam5CorrPattern = /^Cam5_\d{8}_\d{6}_\d{3}(_[A-Z])?_Corr\.(jpg|png|jpeg)$/;
export const pano360Pattern = /^Pano360_\d{8}_\d{6}_\d{3}(_[A-Z])?\.(jpg|png|jpeg)$/;
export const equirecPattern = /^Equirec_\d{8}_\d{6}_\d{3}(_[A-Z])?\.(jpg|png|jpeg)$/;
export const depth8Pattern = /^Depth8_\d{8}_\d{6}_\d{3}(_[A-Z])?\.(jpg|png|jpeg)$/;
export const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

export const WRONG_PASSWORD_CODE = 999;
