import { Button, Modal } from 'react-bootstrap';
import { DetectedLocationData } from '../../../detectedLocation/detectedLocationType';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashCan } from '@fortawesome/free-regular-svg-icons';
import { useAppDispatch } from '../../../../app/hooks';
import { deleteDetectedLocationDetail } from '../../../detectedLocation/detectedLocationApi';
import { useState } from 'react';

type props = {
  rowData: DetectedLocationData;
};

function ActionDeleteColumn({ rowData }: props) {
  const dispatch = useAppDispatch();
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
      <Button variant="primary" onClick={handleShow}>
        <FontAwesomeIcon icon={faTrashCan} />
      </Button>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Saved detected location</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete item {rowData.folderName}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            No
          </Button>
          <Button variant="primary" onClick={() => dispatch(deleteDetectedLocationDetail(rowData))}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ActionDeleteColumn;
