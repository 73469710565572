import React, { useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Outlet } from 'react-router-dom';
import Footer from '../Footer/Footer';
import Header from '../Header/Header';
import { Navigate } from 'react-router-dom';
import { getUserProfile } from '../../features/auth/authApi';
import { selectAuth } from '../../features/auth/authSlice';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import MyLoader from '../MyLoader';
import {
  selectSyncDataLoading,
  selectSyncDataMessage
} from '../../features/syncData/syncDataSlice';
import { toast, ToastContainer } from 'react-toastify';

interface MainLayoutProps {
  children?: React.ReactNode;
}

const MainLayout: React.FC<MainLayoutProps> = () => {
  const dispatch = useAppDispatch();

  const { error } = useAppSelector(selectAuth);

  useEffect(() => {
    dispatch(getUserProfile());
  }, [dispatch]);

  if (error) {
    return <Navigate to="/login" replace={true} />;
  }
  const syncDataLoading = useAppSelector(selectSyncDataLoading);
  const syncDataMessage = useAppSelector(selectSyncDataMessage);
  useEffect(() => {
    if (syncDataMessage) {
      toast.success(syncDataMessage, {
        position: 'top-center',
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light'
      });
    }
  }, [syncDataMessage]);
  return (
    <>
      <MyLoader active={syncDataLoading} text={'Sorry, sync data will take some minutes'}>
        <Header></Header>

        <Container fluid className='d-flex flex-column min-vh-100'>
          <Row>
            <Col>
              <Outlet></Outlet>
            </Col>
          </Row>
          <Row className="mt-auto">
            <Col className="p-0">
              <Footer></Footer>
            </Col>
          </Row>
        </Container>
        <ToastContainer></ToastContainer>
      </MyLoader>
    </>
  );
};

export default MainLayout;
