import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';
import { selectIslogined } from '../../features/auth/authSlice';

const PrivateRoute: React.FC = () => {
  const isAuthenticated = useSelector(selectIslogined);
  if (!isAuthenticated) {
    return <Navigate to="/Login" replace={true} />;
  }
  return <Outlet />;
};

export default PrivateRoute;
