import 'bootstrap-icons/font/bootstrap-icons.css';
import BackgroundLogin from '../../Assets/images/login.png';
import { Container } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { selectIslogined } from '../../features/auth/authSlice';
import { Navigate } from 'react-router-dom';
import ForgotPassword from '../../features/auth/components/Core/ForgotPassword';
import { ToastContainer } from 'react-toastify';

function ForgotPasswordPage() {
  const isAuthenticated = useSelector(selectIslogined);
  if (isAuthenticated) {
    return <Navigate to="/detected-locations" replace={true} />;
  }
  return (
    <Container className="d-flex">
      <ForgotPassword></ForgotPassword>
      <img src={BackgroundLogin} alt="background Register" className="position-absolute background-login h-100 w-50 top-0" />
      <ToastContainer />
    </Container>
  );
}

export default ForgotPasswordPage;
