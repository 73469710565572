export const a = 1;
import { createSlice } from '@reduxjs/toolkit';
import { UploadFolderState } from './uploadFolderType';
import { uploadDataset } from './uploadFolderApi';
import { RootState } from '../../app/store';

/* eslint-disable */

const initialState: UploadFolderState = {
  data: null,
  error: null,
  success: false,
  files: null,
  loading: false,
  processUpload: 0,
  detectedLocationIds: []
};

export const selectDetectedLocationIds = (state: RootState) => state.uploadFolder.detectedLocationIds;

const UploadFolderSlice = createSlice({
  name: 'uploadFolder',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(uploadDataset.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(uploadDataset.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(uploadDataset.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
  },
  reducers: {
    addNewFiles: (state, action) => {
      //   state.files = action.payload.map((item: FileWithPath) => item.path);
    },
    setSelectedIds: (state, action) => {
      state.detectedLocationIds = action.payload;
    }
  }
});
export const { addNewFiles, setSelectedIds } = UploadFolderSlice.actions;

export default UploadFolderSlice.reducer;
