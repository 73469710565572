import { useState, useRef } from 'react';
import { Button, Modal } from 'react-bootstrap';
import ReactSignatureCanvas from 'react-signature-canvas';
import SignatureCanvas from 'react-signature-canvas';
interface Props {
  handleChange: (data: string) => void;
}
const SignPad = (props: Props) => {
  const [show, setShow] = useState(false);
  const [sign, setSign] = useState('');
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const signRef = useRef() as React.MutableRefObject<ReactSignatureCanvas>;

  const trim = () => {
    if (signRef.current.isEmpty()) {
      setSign('');
      props.handleChange('');
    } else {
      setSign(signRef.current.getTrimmedCanvas().toDataURL('image/png'));
      props.handleChange(signRef.current.getTrimmedCanvas().toDataURL('image/png'));
    }
    handleClose();
  };
  const clear = () => {
    signRef.current.clear();
  };
  return (
    <div className="">
      {!sign && (
        <Button variant="primary" onClick={handleShow}>
          Click to Signature
        </Button>
      )}
      {sign && (
        <div className="p-2 border rounded" onClick={handleShow}>
          <img className="w-100" src={sign} alt="" />
        </div>
      )}
      <Modal show={show} onHide={handleClose} centered backdrop="static" keyboard={false}>
        <Modal.Body>
          <SignatureCanvas
            ref={signRef}
            penColor="black"
            canvasProps={{ height: 200, className: 'sigCanvas w-100 h-100' }}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="secondary" onClick={clear}>
            Clean
          </Button>
          <Button variant="primary" onClick={trim}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default SignPad;
