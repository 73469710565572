import React from 'react';
import footerImg from '../../Assets/images/footer.png';
interface FooterProps {
  children?: React.ReactNode;
}

const Footer: React.FC<FooterProps> = () => {
  return <img src={footerImg} className="w-100 "></img>;
};

export default Footer;
