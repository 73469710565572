import { useEffect, useState, forwardRef, useRef } from 'react';
import { Row, Col, InputGroup, FormControl, Dropdown } from 'react-bootstrap';
import { fetchLocation, fetchOneMapData } from '../onemapApi';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { clearSelectedLocationB, selectSearchResult, selectSelectedLocationB, setSelectedLocationB } from '../onemapSlice';
import { ILocationOnemap } from '../onemapType';
import { BsXLg } from 'react-icons/bs';
const CustomToggle = forwardRef<
  HTMLDivElement,
  {
    children: React.ReactNode;
    onClick: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  }
>(function CustomToggle({ children, onClick }, ref) {
  return (
    <div
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}>
      {children}
    </div>
  );
});
CustomToggle.displayName = 'CustomToggle';
const Search = (props: any) => {
  const [searchTerm, setSearchTerm] = useState('');
  const dispatch = useAppDispatch();
  const searchResults = useAppSelector(selectSearchResult);
  const [showResults, setShowResults] = useState(false);
  const selectedLocation = useAppSelector(selectSelectedLocationB);
  const prevPropsRef = useRef();

  useEffect(() => {
    const prevPropsString = JSON.stringify(prevPropsRef.current);
    const currentPropsString = JSON.stringify(props?.props?.route_geometry);

    if (prevPropsString !== currentPropsString) {
      prevPropsRef.current = props?.props?.route_geometry;
      if (props?.props?.route_geometry) {
        setSearchTerm(props?.props?.route_summary?.end_point);
      }
    }
  }, [props?.props?.route_geometry]);

  useEffect(() => {
    const pattern = /^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?),\s*[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)$/; // regular expression for coordinates
    const isValidInput = pattern.test(searchTerm);

    if (isValidInput) {
      dispatch(fetchLocation({ location: searchTerm }));
    } else if (searchTerm) {
      dispatch(fetchOneMapData(searchTerm));
    }
  }, [searchTerm, dispatch]);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };
  const renderSearchResults = () => {
    return (
      <Dropdown.Menu style={{ height: '70vh', overflowY: 'auto' }}>
        {searchResults &&
          searchResults?.map((result, index) => (
            <Dropdown.Item
              active={result.LATITUDE == selectedLocation?.LATITUDE}
              key={index}
              onClick={() => {
                setShowResults(false);
                handleSelect(result);
              }}>
              <Row>
                <Col lg="12">{result.BUILDING}</Col>
                <Col lg="12">{result.ADDRESS}</Col>
              </Row>
            </Dropdown.Item>
          ))}
      </Dropdown.Menu>
    );
  };
  const handleSelect = (result: ILocationOnemap) => {
    setSearchTerm(`${result.BUILDING} ${result.ADDRESS}`);
    // do something with the selected result
    dispatch(setSelectedLocationB(result));
  };
  // Dropdown needs access to the DOM node in order to position the Menu

  return (
    <>
      <Row className="mb-2">
        <Col md={12} lg="12">
          <Dropdown
            as={InputGroup}
            show={!!searchResults?.length && !!searchTerm && showResults}
            // onBlur={() => setShowResults(false)
          >
            <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
              <Row>
                <Col>
                  <InputGroup>
                    <FormControl onFocus={() => setShowResults(true)} placeholder="Search" value={searchTerm} onChange={handleSearchChange} />
                    <InputGroup.Text id="basic-addon1">
                      <BsXLg
                        role={'button'}
                        onClick={() => {
                          setSearchTerm('');
                          dispatch(clearSelectedLocationB());
                        }}></BsXLg>
                    </InputGroup.Text>
                  </InputGroup>
                </Col>
              </Row>
            </Dropdown.Toggle>

            {renderSearchResults()}
          </Dropdown>
        </Col>
      </Row>
    </>
  );
};

export default Search;
