import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import Form from 'react-bootstrap/Form';
import { updateCredentialUser } from '../../authApi';
import { UserProfile } from '../../authType';
import { selectUserProfile } from '../../authSlice';
import { message } from 'antd';

type props = {
  rowData: UserProfile;
};

function ActionRoleColumn({ rowData }: props) {
  const [messageApi, contextHolder] = message.useMessage();
  const success = () => {
    messageApi.open({
      type: 'success',
      content: 'Update successfully!'
    });
  };
  const dispatch = useAppDispatch();
  const profile = useAppSelector(selectUserProfile);
  const handleOnChange = (value: number) => {
    dispatch(updateCredentialUser({ userId: rowData.id, type: value })).then((res: any) => {
      if (res.meta.requestStatus === 'fulfilled') {
        success();
      }
    });
  };
  return (
    <>
      {contextHolder}
      <Form>
        <Form.Select
          onChange={(e) => {
            const value = e.target.value;
            handleOnChange(Number(value));
          }}
          disabled={profile !== null && profile.email === rowData.email}
          defaultValue={rowData.type}>
          <option value={2}>Super Admin</option>
          <option value={1}>Admin</option>
          <option value={0}>Normal</option>
        </Form.Select>
      </Form>
    </>
  );
}

export default ActionRoleColumn;
