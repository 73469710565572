import { Navbar, Container, Dropdown } from 'react-bootstrap';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { selectAuth } from '../../features/auth/authSlice';
import { getNewDataSet } from '../../features/syncData/syncDataApi';
import { userLogout } from '../../features/auth/authApi';
import { NavLink } from 'react-router-dom';

function Header() {
  const dispatch = useAppDispatch();
  const { userInfo } = useAppSelector(selectAuth);
  const fullName = userInfo?.fullName || '';
  const handleLogout = () => {
    dispatch(userLogout());
  };
  const handleSyncData = () => {
    dispatch(getNewDataSet());
  };
  return (
    <Navbar bg="primary" expand="lg">
      <Container>
        <Navbar.Brand href="/" className="text-white me-auto">
          PathWatcher
        </Navbar.Brand>

        <Dropdown className="my-2">
          <Dropdown.Toggle
            variant="link"
            id="avatar-dropdown"
            className="px-0 text text-decoration-none">
            <span>
              <span className="me-2 text-white">{fullName}</span>
              <span className="bg-secondary text-white p-3  rounded-circle bg-opacity-75">
                {fullName.at(0)?.toUpperCase()}
              </span>
            </span>
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <NavLink to={'profile'} className={'text-decoration-none'}>
              <Dropdown.Item as="div">Profile </Dropdown.Item>
            </NavLink>
            <Dropdown.Item
              onClick={() => {
                handleSyncData();
              }}>
              Sync Data
            </Dropdown.Item>
            <Dropdown.Divider />
            <Dropdown.Item
              onClick={() => {
                handleLogout();
              }}>
              Logout
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </Container>
    </Navbar>
  );
}

export default Header;
