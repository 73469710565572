import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Outlet } from 'react-router-dom';
import Footer from '../Footer/Footer';

const Layout: React.FC = () => {
  return (
    <Container fluid>
      <Row>
        <Col>
          <Outlet></Outlet>
        </Col>
      </Row>
      <Row>
        <Col>
          <Row>
            <Col className="p-0 position-fixed bottom-0  start-50 translate-middle-x w-100">
              <Footer></Footer>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default Layout;
