import { DateTime } from 'luxon';
import { DetectedLocationData } from '../../../detectedLocation/detectedLocationType';
import DataTable, { TableColumn } from 'react-data-table-component';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { selectDetectedLocation } from '../../../detectedLocation/detectedLocationSlice';
import { Container } from 'react-bootstrap';
import ActionDeleteColumn from './ActionDeleteColumn';
import ActionColumn from '../../../detectedLocation/components/ActionColumn';
import { useEffect, useState } from 'react';
import { getDetectedLocation } from '../../../detectedLocation/detectedLocationApi';
import { useSearchParams } from 'react-router-dom';
import PdfInformationFormModal from '../../../detectedLocation/components/PdfInformationFormModal';
import { Progress } from 'antd';

const SavedDetectedLocation = () => {
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const { data, loading, page, perPage, total } = useAppSelector(selectDetectedLocation);
  const [order, setOrder] = useState<TableColumn<DetectedLocationData>>();
  const [orderBy, setOrderBy] = useState<string>();
  const [processed, setProcessed] = useState(0);

  const handlePageChange = (page: number) => {
    if (searchParams.get('page')?.toString() != page.toString()) {
      searchParams.set('page', page.toString());
      fetchData(perPage, page, order?.sortField, orderBy);
    }
  };
  const handlePerRowsChange = async (newPerPage: number, page: number) => {
    searchParams.set('perPage', newPerPage.toString());
    fetchData(newPerPage, page, order?.sortField, orderBy);
  };

  const fetchData = (perPage?: number, page?: number, orderBy?: string, order?: string) => {
    dispatch(
      getDetectedLocation({
        fromDate: DateTime.fromFormat('20210427', 'yyyyMMdd').toFormat('yyyyMMdd'),
        toDate: DateTime.fromFormat('20210427', 'yyyyMMdd').toFormat('yyyyMMdd'),
        isCrackDetected: true,
        page: searchParams.get('page') ? parseInt(searchParams.get('page') || '') : undefined,
        perPage: searchParams.get('perPage') ? parseInt(searchParams.get('perPage') || '') : undefined,
        order: searchParams.get('order') ? searchParams.get('order') || '' : undefined,
        orderBy: searchParams.get('orderBy') ? searchParams.get('orderBy') || '' : undefined,
        fromTime: searchParams.get('fromTime') ? searchParams.get('fromTime') || '' : undefined,
        toTime: searchParams.get('toTime') ? searchParams.get('toTime') || '' : undefined,
        triggerType: searchParams.get('triggerType') ? searchParams.get('triggerType') || '' : undefined,
        latitude: searchParams.get('latitude') ? parseFloat(searchParams.get('latitude') || '') : undefined,
        longitude: searchParams.get('longitude') ? parseFloat(searchParams.get('longitude') || '') : undefined,
        radius: searchParams.get('radius') ? parseFloat(searchParams.get('radius') || '') : undefined,
        isProfile: true
      })
    );
  };

  const handleProcessed = (data: any) => {
    setProcessed(data);
    return data;
  };

  const handleFetchDataPdf = () => {
    console.log('handleFetchDataPdf');
  };

  useEffect(() => {
    dispatch(
      getDetectedLocation({
        fromDate: DateTime.fromFormat('20210427', 'yyyyMMdd').toFormat('yyyyMMdd'),
        toDate: DateTime.fromFormat('20210427', 'yyyyMMdd').toFormat('yyyyMMdd'),
        isCrackDetected: true,
        page: searchParams.get('page') ? parseInt(searchParams.get('page') || '') : undefined,
        perPage: searchParams.get('perPage') ? parseInt(searchParams.get('perPage') || '') : undefined,
        order: searchParams.get('order') ? searchParams.get('order') || '' : undefined,
        orderBy: searchParams.get('orderBy') ? searchParams.get('orderBy') || '' : undefined,
        fromTime: searchParams.get('fromTime') ? searchParams.get('fromTime') || '' : undefined,
        toTime: searchParams.get('toTime') ? searchParams.get('toTime') || '' : undefined,
        triggerType: searchParams.get('triggerType') ? searchParams.get('triggerType') || '' : undefined,
        latitude: searchParams.get('latitude') ? parseFloat(searchParams.get('latitude') || '') : undefined,
        longitude: searchParams.get('longitude') ? parseFloat(searchParams.get('longitude') || '') : undefined,
        radius: searchParams.get('radius') ? parseFloat(searchParams.get('radius') || '') : undefined,
        isProfile: true
      })
    );
  }, [dispatch]);

  const columns: TableColumn<DetectedLocationData>[] = [
    {
      name: '',
      width: '50px',
      cell: (row) => <ActionColumn rowData={row} />
    },
    {
      name: 'No.',
      cell: (row, rowIndex) => <>{page && perPage ? rowIndex + 1 + perPage * (page - 1) : rowIndex}</>,
      sortable: false,
      width: '70px'
    },
    {
      name: 'Name',
      selector: (row) => row.folderName,
      sortable: true,
      sortField: 'folderName',
      width: '180px'
    },
    {
      name: 'Datetime',
      selector: (row) => DateTime.fromFormat(`${row.date}${row.time}`, 'yyyyMMddHHmmssSSS').toFormat('dd/MM/yyyy HH:mm:ss'),
      sortable: true,
      sortField: 'datetime',
      width: '160px'
    },
    {
      name: 'Latitude',
      selector: (row) => row.latitude,
      sortable: true,
      sortField: 'latitude'
    },
    {
      name: 'Longtitude',
      selector: (row) => row.longitude,
      sortable: true,
      sortField: 'longitude'
    },
    {
      name: 'Detected',
      selector: (row) => (row.isCrackDetected ? 'Yes' : 'No'),
      sortable: true,
      sortField: 'isCrackDetected'
    },
    {
      name: 'Trigger type',
      selector: (row) => row.triggerType.charAt(0).toUpperCase() + row.triggerType.slice(1).toLocaleLowerCase(),
      sortable: true,
      sortField: 'folderName',
      width: '150px'
    },
    {
      name: 'Action',
      width: '100px',
      cell: (row) => <ActionDeleteColumn rowData={row} />
    }
  ];
  return (
    <Container>
      {processed > 0 ? <Progress percent={processed} size="small" /> : null}

      <div className="table-responsive">
        <DataTable
          noHeader={true}
          highlightOnHover={true}
          pointerOnHover={true}
          striped={true}
          pagination
          columns={columns}
          data={data || []}
          paginationTotalRows={total}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handlePerRowsChange}
          customStyles={{
            rows: {}
          }}
        />
      </div>
      <PdfInformationFormModal onData={handleProcessed} handleFetchDataPdf={handleFetchDataPdf}></PdfInformationFormModal>
    </Container>
  );
};

export default SavedDetectedLocation;
