import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import { Line } from 'react-chartjs-2';
import { Container, Row, Col, Form } from 'react-bootstrap';
import ReactDatePicker from 'react-datepicker';
import classNames from 'classnames';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

import { useAppSelector } from '../../../app/hooks';
import { selectDetectedLocation } from '../detectedLocationSlice';

const TrendAnalysis = (props: any) => {
  const { trend } = useAppSelector(selectDetectedLocation);

  const {
    filterBy,
    setFilterBy,
    startDateTrend,
    setStartDateTrend,
    endDate,
    setEndDate,
    startMonth,
    setStartMonth,
    endMonth,
    setEndMonth,
    startYear,
    setStartYear,
    endYear,
    setEndYear,
    labelDay
  } = props;

  const data = {
    labels: labelDay,
    datasets: [
      {
        label: 'Number of detected location',
        data: [123, -456, 789, -321, 654, -987, 654],
        borderColor: 'rgb(255, 99, 132)',
        backgroundColor: 'rgba(255, 99, 132, 0.5)'
      }
    ]
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top' as const
      },
      title: {
        display: true,
        text: 'Trend analysis'
      }
    },
    scales: {
      x: {
        display: true,
        title: {
          display: true,
          text: filterBy && filterBy.charAt(0).toUpperCase() + filterBy.slice(1)
        }
      },
      y: {
        display: true,
        title: {
          display: true,
          text: 'Number of detected location'
        }
      }
    }
  };

  switch (filterBy) {
    case 'day':
      if (trend) {
        data.labels = trend.data;
        data.datasets[0].data = trend.count;
      }
      break;
    case 'month':
      if (trend) {
        data.labels = trend.data;
        data.datasets[0].data = trend.count;
      }
      break;
    case 'year':
      if (trend) {
        data.labels = trend.data;
        data.datasets[0].data = trend.count;
      }
      break;
    default:
      break;
  }

  const day = classNames({
    day: filterBy === 'day'
  });

  const month = classNames({
    month: filterBy === 'month'
  });

  const year = classNames({
    year: filterBy === 'year'
  });

  const filterPassedDate = (time: Date) => {
    let currentDate = new Date();
    const selectedDate = new Date(time);
    if (startDateTrend) {
      currentDate = startDateTrend;
    }
    return currentDate.getTime() < selectedDate.getTime();
  };

  return (
    <div>
      <Container fluid>
        <Row className="mt-3">
          <Col lg="4">
            <Form.Group className="mb-3">
              <Form.Label className="form-label">Filter by</Form.Label>
              <Form.Select
                value={filterBy}
                onChange={(e) => {
                  setFilterBy(e.target.value);
                }}>
                <option value="day">Day</option>
                <option value="month">Month</option>
                <option value="year">Year</option>
              </Form.Select>
            </Form.Group>
          </Col>
          <Col lg="3">
            <Form.Group className={`mb-3 ${month} ${year}`}>
              <Form.Label className="form-label">Start date</Form.Label>
              <ReactDatePicker
                selected={startDateTrend}
                onChange={(date) => {
                  if (date) {
                    if (endDate.getTime() < date.getTime()) {
                      setEndDate(date);
                    }
                    setStartDateTrend(date);
                  }
                }}
                className="form-control"
                customInput={<input type="text" readOnly />}
                placeholderText="dd/MM/yyyy"
                dateFormat="dd/MM/yyyy"
              />
            </Form.Group>
            <Form.Group className={`mb-3 ${day} ${year}`}>
              <Form.Label className="form-label">Start month</Form.Label>
              <ReactDatePicker
                selected={startMonth}
                onChange={(month) => {
                  if (month) {
                    setStartMonth(month);
                  }
                }}
                className="form-control"
                dateFormat="MM/yyyy"
                showMonthYearPicker
                showFullMonthYearPicker
              />
            </Form.Group>
            <Form.Group className={`mb-3 ${day} ${month}`}>
              <Form.Label className="form-label">Start year</Form.Label>
              <ReactDatePicker
                selected={new Date(startYear, 0)}
                onChange={(month) => {
                  if (month) {
                    setStartYear(month.getFullYear());
                  }
                }}
                className="form-control"
                showYearPicker
                dateFormat="yyyy"
              />
            </Form.Group>
          </Col>
          <Col lg="3">
            <Form.Group className={`mb-3 ${month} ${year}`}>
              <Form.Label className="form-label">End date</Form.Label>
              <ReactDatePicker
                selected={endDate}
                onChange={(date) => {
                  if (date) {
                    setEndDate(date);
                  }
                }}
                className="form-control"
                placeholderText="dd/MM/yyyy"
                dateFormat="dd/MM/yyyy"
                filterDate={filterPassedDate}
              />
            </Form.Group>
            <Form.Group className={`mb-3 ${day} ${year}`}>
              <Form.Label className="form-label">End month</Form.Label>
              <ReactDatePicker
                selected={endMonth}
                onChange={(month) => {
                  if (month) {
                    setEndMonth(month);
                  }
                }}
                className="form-control"
                dateFormat="MM/yyyy"
                showMonthYearPicker
                showFullMonthYearPicker
              />
            </Form.Group>
            <Form.Group className={`mb-3 ${day} ${month}`}>
              <Form.Label className="form-label">End year</Form.Label>
              <ReactDatePicker
                selected={new Date(endYear, 0)}
                onChange={(year) => {
                  if (year) {
                    setEndYear(year.getFullYear());
                  }
                }}
                className="form-control"
                showYearPicker
                dateFormat="yyyy"
              />
            </Form.Group>
          </Col>
        </Row>
      </Container>
      <Line options={options} data={data} />
    </div>
  );
};
export default TrendAnalysis;
