import React, { useState } from 'react';
import { Form, FormControl, Button, Container, Row, Col, InputGroup } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { toast, ToastContent } from 'react-toastify';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { loginI, userLogin } from '../../authApi';
import { selectLoading, selectLoginError } from '../../authSlice';
interface LoginProps {
  children?: React.ReactNode;
}

const Login: React.FC<LoginProps> = () => {
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [isShowPassword, setIsShowPassword] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const isLoading = useAppSelector(selectLoading);
  const error = useAppSelector(selectLoginError);
  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const payload: loginI = { email, password };
    if (!email || !password) {
      return toast.error('Please enter all information', {
        position: 'top-center',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light'
      });
    }
    dispatch(userLogin(payload)).then((res) => {
      if (res.meta.requestStatus == 'rejected') {
        toast.error(res.payload as ToastContent, {
          position: 'top-center',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light'
        });
      }
    });
    // await dispatch(getUserProfile());
  };
  return (
    <>
      <Container className="position-absolute top-50 start-50 translate-middle">
        <Row className="justify-content-center">
          <Col md="5" className="shadow p-5 mb-5 bg-body rounded rounded-4">
            <Row>
              <Col className="mb-4 d-flex justify-content-center" lg="12">
                <div className="fs-1">PathWatcher</div>
              </Col>
              <Col className="mb-1 d-flex justify-content-center" lg="12">
                <div className="fs-4">Log in to your account</div>
              </Col>
              <Col className="mb-3 d-flex justify-content-center" lg="12">
                <div className="fs-6 text-secondary text-opacity-50">Welcome back! Please enter your details.</div>
              </Col>
            </Row>

            <Form onSubmit={handleSubmit} autoComplete="off">
              <Form.Group controlId="formBasicEmail" className="mb-3">
                <Form.Label>Email</Form.Label>
                <FormControl type="email" placeholder="Enter email" value={email} onChange={(e) => setEmail(e.target.value)} size="lg" />
              </Form.Group>
              <Form.Group controlId="formBasicPassword" className="mb-3">
                <Form.Label>Password</Form.Label>
                <InputGroup className="mb-3">
                  <FormControl
                    type={isShowPassword ? 'text' : 'password'}
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    size="lg"
                  />
                  <InputGroup.Text
                    id="basic-addon1"
                    role="button"
                    onClick={() => {
                      setIsShowPassword(!isShowPassword);
                    }}>
                    <i className={isShowPassword ? 'bi bi-eye text-primary' : 'bi bi-eye-slash text-primary'}></i>
                  </InputGroup.Text>
                </InputGroup>
              </Form.Group>
              <Row className="gap-2 justify-content-center mb-2">
                <Col className="d-flex justify-content-between">
                  <NavLink className="text-decoration-none" to={'/register'}>
                    Create new account?
                  </NavLink>
                  <NavLink className="text-decoration-none" to={'/forgot-password'}>
                    Forgot password?
                  </NavLink>
                </Col>
              </Row>
              <div className="d-grid gap-2">
                <Button disabled={isLoading} variant="primary" size="lg" type="submit">
                  {isLoading ? 'Loading…' : 'Sign in'}
                </Button>
              </div>
            </Form>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Login;
