import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { getNewDataSet } from './syncDataApi';
import { SyncDataState } from './syncDataType';

const initialState: SyncDataState = {};

export const selectSyncDataLoading = (state: RootState) => state.syncData.loading;
export const selectSyncDataMessage = (state: RootState) => state.syncData.message;

const SyncDataSlice = createSlice({
  name: 'syncData',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getNewDataSet.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.message = '';
    });
    builder.addCase(getNewDataSet.fulfilled, (state, action) => {
      state.loading = false;
      state.message = action.payload.message;
    });
    builder.addCase(getNewDataSet.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
  },
  reducers: {}
});
// export const {} = SyncDataSlice.actions;

export default SyncDataSlice.reducer;
