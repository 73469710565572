import 'bootstrap-icons/font/bootstrap-icons.css';
import BackgroundLogin from '../../Assets/images/login.png';
import { Container } from 'react-bootstrap';
import './Register.scss';
import { useSelector } from 'react-redux';
import { selectIslogined } from '../../features/auth/authSlice';
import { Navigate } from 'react-router-dom';
import Register from '../../features/auth/components/Core/Register';

function RegisterPage() {
    const isAuthenticated = useSelector(selectIslogined);
    if (isAuthenticated) {
    return <Navigate to="/detected-locations" replace={true} />;
  }
  return (
    <Container className="d-flex">
      <Register></Register>
      <img
        src={BackgroundLogin}
        alt="background Register"
        className="position-absolute background-login h-100 w-50 top-0"
      />
    </Container>
  );
}

export default RegisterPage;
