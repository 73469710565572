import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import authInstance from '../../Axios/authInstance';
import { DetectedLocationData } from '../detectedLocation/detectedLocationType';
import { FetchLocationParams, IFetchOneMapData, IGeocodeOneMapData } from './onemapType';

export const fetchOneMapData = createAsyncThunk<IFetchOneMapData, string>(
  'oneMap/fetchData',
  async (searchTerm) => {
    const response = await fetch(
      `https://www.onemap.gov.sg/api/common/elastic/search?searchVal=${searchTerm}&returnGeom=Y&getAddrDetails=Y&pageNum=1`
    );
    const data = await response.json();
    // modify the data as needed
    return data as IFetchOneMapData;
  }
);
export const getDetectedLocationWithinRadius = createAsyncThunk<
  DetectedLocationData[],
  { latitude?: number; longitude?: number; radius?: number },
  { state: RootState }
>(
  'detectedLocation/getDetectedLocationWithinRadius',
  async ({ latitude, longitude, radius }, { rejectWithValue }) => {
    try {
      const { data } = await authInstance.get(`/api/detected-locations/get-within-radius`, {
        params: { latitude, longitude, radius }
      });
      return data;
      // eslint-disable-next-line
    } catch (error: any) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
export const fetchLocation = createAsyncThunk<IGeocodeOneMapData, FetchLocationParams>(
  'location/fetchLocation',
  async ({ location }: FetchLocationParams) => {
    const locationArr = location.split(',');
    const response = await authInstance.get(`/api/onemap/reverse-geocode`, {
      params: { lat: locationArr[0], lng: locationArr[1] }
    });
    return response.data;
  }
);
