import atob from 'atob';
import { FileWithPath } from 'react-dropzone';

export const addPrefixToImageBase64 = (base64String: string, prefix: string): string => {
  const prefixWithBase64 = `${prefix}${base64String}`;
  return prefixWithBase64;
};

export function parseBase64ToPdf(base64String: string) {
  const byteCharacters = atob(base64String);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);
  const pdfBlob = new Blob([byteArray], { type: 'application/pdf' });
  return pdfBlob;
}
export const isDirectory = (file: FileWithPath) => {
  return file.type === '' && file.size === 0;
};

const units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

export function niceBytes(x: number) {
  let l = 0,
    n = x || 0;

  while (n >= 1024 && ++l) {
    n = n / 1024;
  }

  return n.toFixed(n < 10 && l > 0 ? 1 : 0) + ' ' + units[l];
}
export const getFolder = (path: string) => {
  const parts = path.split('/');
  const folderName = parts[parts.length - 2];
  return folderName;
};

export const getFolderFromBatchPavementMask = (path: string) => {
  const regex = /PavementMask_(\d{8}_\d{6}_\d{3})\.txt/;
  const match = path.match(regex);
  if (match && match[1]) {
    return match[1];
  } else return '';
};
export function isValidPavementMaskFileName(fileName: string): boolean {
  const regex = /^PavementMask_\d{8}_\d{6}_\d{3}\.txt$/;
  return regex.test(fileName);
}
export function isValidSensorFileName(fileName: string): boolean {
  const regex = /^Sensors_\d{8}_\d{6}_\d{3}\.(txt|json)$/;
  return regex.test(fileName);
}
export function isValidCam1ImageFileName(fileName: string): boolean {
  const regex = /^Cam1_\d{8}_\d{6}_\d{3}\.(jpg|png|jpeg)$/;
  return regex.test(fileName);
}
export function isValidCam2ImageFileName(fileName: string): boolean {
  const regex = /^Cam2_\d{8}_\d{6}_\d{3}\.(jpg|png|jpeg)$/;
  return regex.test(fileName);
}
export function isValidCam3ImageFileName(fileName: string): boolean {
  const regex = /^Cam3_\d{8}_\d{6}_\d{3}\.(jpg|png|jpeg)$/;
  return regex.test(fileName);
}
export function isValidCam4ImageFileName(fileName: string): boolean {
  const regex = /^Cam4_\d{8}_\d{6}_\d{3}\.(jpg|png|jpeg)$/;
  return regex.test(fileName);
}
export function isValidCam5ImageFileName(fileName: string): boolean {
  const regex = /^Cam5_\d{8}_\d{6}_\d{3}\.(jpg|png|jpeg)$/;
  return regex.test(fileName);
}
export function isValidCam5CorrImageFileName(fileName: string): boolean {
  const regex = /^Cam5_\d{8}_\d{6}_\d{3}_Corr\.(jpg|png|jpeg)$/;
  return regex.test(fileName);
}
export function isValidPanoImageFileName(fileName: string): boolean {
  const regex = /^Pano360_\d{8}_\d{6}_\d{3}\.(jpg|png|jpeg)$/;
  return regex.test(fileName);
}
