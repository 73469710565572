import React, { useEffect, useMemo, useState } from 'react';
import { FileRejection, FileWithPath } from 'react-dropzone';
import { useDropzone } from 'react-dropzone';
import { listRegexFile } from '../../../utils/const';
import { getFolder, getFolderFromBatchPavementMask } from '../../../utils/helper';
import { Button, Spinner } from 'react-bootstrap';
import { FileWithPathAndError } from '../uploadFolderType';

// interface FolderUploadProps {}
const baseStyle = {
  flex: 1,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out'
};

const focusedStyle = {
  borderColor: '#ff7200'
};

const acceptStyle = {
  borderColor: '#ff7200'
};

const rejectStyle = {
  borderColor: '#ff1744'
};
interface Props {
  handleFiles: (acceptedFiles: FileWithPath[]) => void;
  handleRejectedFiles: (fileRejection: FileRejection[]) => void;
  filesUploaded: FileWithPathAndError[];
}

const FolderUploadDropzone: React.FC<Props> = (props) => {
  function nameLengthValidator(file: FileWithPath) {
    if (!file.path || (file.name == file.path && !file.path.includes('PavementMask'))) {
      return {
        code: 'wrong-dataset',
        message: `This file does not belong to any dataset`
      };
    }
    if (!listRegexFile.some((rx) => rx.test(file.name))) {
      return {
        code: 'wrong-file-name',
        message: `Sorry, this file is wrong name format `
      };
    }

    if (!file.name.includes(getFolder(file.path)) && !file.name.includes('Corr') && !file.name.includes(getFolderFromBatchPavementMask(file.path))) {
      return {
        code: 'wrong-file-dataset-consistency',
        message: `Sorry, this file name is not consistency with dataset`
      };
    }
    return null;
  }
  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } = useDropzone({
    noClick: true,
    onDropAccepted: async (files) => {
      setIsLoading(true);
      await props.handleFiles(files);
      setIsLoading(false);
    },
    onDropRejected: (files) => {
      props.handleRejectedFiles(files);
    },
    validator: nameLengthValidator,
    accept: {
      'image/png': ['.jpg'],
      'text/plain': ['.txt'],
      'application/json': ['.json']
    }
  });
  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {})
    }),
    [isFocused, isDragAccept, isDragReject]
  );
  const ref = React.useRef<HTMLInputElement>(null);
  const formRef = React.useRef<HTMLFormElement>(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleOpenDialog = () => {
    if (ref.current) {
      ref.current.click();
    }
  };

  React.useEffect(() => {
    if (ref.current !== null) {
      ref.current.setAttribute('directory', '');
      ref.current.setAttribute('webkitdirectory', '');
      ref.current.setAttribute('mozdirectory', '');
      ref.current.setAttribute('odirectory', '');
      ref.current.setAttribute('msdirectory', '');
    }
  }, [ref]);

  useEffect(() => {
    formRef.current?.reset();
  }, [props.filesUploaded]);

  return (
    <div className="">
      <div {...getRootProps({ style })} className="p-5 d-flex flex-column ">
        <form ref={formRef}>
          <input {...getInputProps({})} ref={ref} />
        </form>
        <div className="p-3">Drag and drop datasets you want to upload here, or Add dataset.</div>
        <div>
          <Button variant="secondary" onClick={handleOpenDialog}>
            Add dataset
          </Button>
        </div>
        {isLoading ? (
          <>
            <br />
            <Spinner variant="primary"></Spinner>
          </>
        ) : null}
      </div>
    </div>
  );
};

export default FolderUploadDropzone;
