import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import loginReducer from '../features/auth/authSlice';
import detectedLocationReducer from '../features/detectedLocation/detectedLocationSlice';
import onemapReducer from '../features/onemap/onemapSlice';
import syncDataReducer from '../features/syncData/syncDataSlice';
import UploadFolderReducer from '../features/uploadFolder/uploadFolderSlice';

export const store = configureStore({
  reducer: {
    auth: loginReducer,
    detectedLocation: detectedLocationReducer,
    oneMap: onemapReducer,
    syncData: syncDataReducer,
    uploadFolder: UploadFolderReducer
  }
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
