import LoadingOverlay from 'react-loading-overlay-ts';

interface props {
  active?: boolean;
  text?: string;
  children?: React.ReactNode;
}
export default function MyLoader({ active, children, text }: props) {
  return (
    <LoadingOverlay active={active} text={text || 'loading...'}>
      {children}
    </LoadingOverlay>
  );
}
