import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { getAllGlacier, updateGlacier } from '../../../detectedLocation/detectedLocationApi';
import { selectYearGlacier } from '../../../detectedLocation/detectedLocationSlice';
import { Row, Col, Checkbox } from 'antd';
import { Container, Button } from 'react-bootstrap';
import { message } from 'antd';

const MigrationS3 = () => {
  const dispatch = useAppDispatch();
  const years = useAppSelector(selectYearGlacier);
  const [checkedList, setCheckedList] = useState<any[]>([]);
  const [messageApi, contextHolder] = message.useMessage();

  useEffect(() => {
    dispatch(getAllGlacier());
  }, [dispatch]);

  const onChange = (checkedValues: any) => {
    setCheckedList(checkedValues);
  };

  const options = [
    { label: '2024', value: 2024 },
    { label: '2023', value: 2023 },
    { label: '2022', value: 2022 },
    { label: '2021', value: 2021 }
  ];

  const success = () => {
    messageApi.success('Migrate to Glacier successfully!');
  };

  const handleMigrate = () => {
    dispatch(updateGlacier({ years: checkedList })).then(() => {
      success();
    });
  };

  return (
    <Container>
      {contextHolder}
      <Row>{years && <Checkbox.Group options={options} defaultValue={years} onChange={onChange} />}</Row>
      <br />
      <Row>
        <Col>
          <Button
            className="me-1"
            onClick={() => {
              handleMigrate();
            }}>
            Migrate
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

export default MigrationS3;
