import { FileWithPathAndError } from '../uploadFolderType';
import { BsCheckCircle, BsUpload, BsXCircle } from 'react-icons/bs';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { TbWorldUpload } from 'react-icons/tb';

type Props = {
  rowData: FileWithPathAndError;
};

const StatusColumn = (props: Props) => {
  const { errors } = props.rowData;

  if (props.rowData.status == 'pending') {
    return (
      <>
        <div className="text-warning">
          <BsUpload className="me-1"></BsUpload>uploading
        </div>
      </>
    );
  } else if (props.rowData.status == 'error' && errors) {
    return (
      <>
        <OverlayTrigger key={'bottom'} placement={'bottom'} overlay={<Tooltip id={`tooltip-bottom`}>{errors[0].message}</Tooltip>}>
          <div className="text-danger">
            <BsXCircle className="me-1"></BsXCircle>errors
          </div>
        </OverlayTrigger>
      </>
    );
  } else if (props.rowData.status == 'success') {
    return (
      <>
        <div className="text-success">
          <BsCheckCircle className="me-1"></BsCheckCircle>success
        </div>
      </>
    );
  } else if (props.rowData.status == 'retry') {
    return (
      <>
        <div className="text-warning">
          <TbWorldUpload className="me-1"></TbWorldUpload>retry
        </div>
      </>
    );
  }
  return (
    <div className="text-info">
      <BsCheckCircle className="me-1"></BsCheckCircle>ready
    </div>
  );
};

export default StatusColumn;
