import React from 'react';
import ProfileSetting from '../../features/auth/components/Core/profileSetting/ProfileSetting';

const ProfilePage = () => {
  return (
    <div>
      <ProfileSetting></ProfileSetting>
    </div>
  );
};

export default ProfilePage;
