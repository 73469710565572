import React, { ChangeEvent } from 'react';
import { Col, Row } from 'react-bootstrap';
import { BsFillXCircleFill, BsX } from 'react-icons/bs';

type Props = {
  onFilter: (e: React.ChangeEvent<HTMLInputElement>) => void;
  filterText: string;
  onClear: () => void;
};

const FilterComponent = (props: Props) => {
  return (
    <div className="input-group ">
      <input
        type="text"
        className="form-control"
        placeholder="Filter by name or folder"
        aria-label="Filter by name folder"
        aria-describedby="button-addon2"
        onChange={(e) => props.onFilter(e)}
        value={props.filterText}
      />
      <button
        className="btn btn-outline-secondary"
        type="button"
        id="button-addon2"
        onClick={() => {
          props.onClear();
        }}>
        <BsX></BsX>
      </button>
    </div>
  );
};

export default FilterComponent;
