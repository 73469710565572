import { DateTime } from 'luxon';
import React, { useEffect, useState, useMemo, useCallback } from 'react';
import DataTable, { SortOrder, TableColumn } from 'react-data-table-component';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { getExportPDF } from '../detectedLocationApi';
import {
  selectDetectedLocation,
  selectDetectedLocationDetail,
  selectDetectedLocationSearch,
  setDetectedLocationDetail
} from '../detectedLocationSlice';
import { DetectedLocationData } from '../detectedLocationType';
import ActionColumn from './ActionColumn';
import { useSearchParams } from 'react-router-dom';
import { FiFile } from 'react-icons/fi';
import StatusColumn from './StatusColumn';
import { Button } from 'react-bootstrap';
import differenceBy from 'lodash/differenceBy';
import { setSelectedIds } from '../../uploadFolder/uploadFolderSlice';
import { LuFileInput } from 'react-icons/lu';

type Props = {
  page: number;
  handlePage: (page: number) => void;
  perPage: number;
  handlePerPage: (perPage: number) => void;
  order: 'asc' | 'desc';
  orderBy: string;
  handleOrder: (order: 'asc' | 'desc') => void;
  handleOrderBy: (orderBy: string) => void;
};

const ClickableIcon: React.FC<any> = ({ defaultKey }) => {
  const dispatch = useAppDispatch();
  const [url, setUrl] = useState<string>('');
  useEffect(() => {
    getURL();
  }, []);
  const getURL = () => {
    dispatch(getExportPDF({ key: defaultKey })).then((res: any) => {
      if (res.meta.requestStatus === 'fulfilled') {
        setUrl(res.payload);
      }
    });
  };
  return url ? (
    <a href={url} target="_blank" rel="noopener noreferrer">
      <LuFileInput size={18} />
    </a>
  ) : null;
};

const DefaultIcon: React.FC<any> = () => {
  return (
    <a target="_blank" rel="noopener noreferrer">
      <LuFileInput color="green" size={18} />
    </a>
  );
};

const DetectedLocationTable: React.FC<Props> = ({ page, handlePage, perPage, handlePerPage, order, orderBy, handleOrder, handleOrderBy }) => {
  const dispatch = useAppDispatch();
  const detectedLocationDetail = useAppSelector(selectDetectedLocationDetail);
  const { data, loading, total, allData } = useAppSelector(selectDetectedLocation);
  const [sortedData, setSortedData] = useState<DetectedLocationData[]>([]);
  // const [localSelectedRows, setLocalSelectedRows] = useState([]);
  const [selectedRows, setSelectedRows] = React.useState([]);
  const [toggleCleared, setToggleCleared] = useState(false);

  useEffect(() => {
    if (allData) {
      setSortedData(allData);
    }
  }, [allData]);

  const columns: TableColumn<DetectedLocationData>[] = [
    {
      name: '',
      width: '50px',
      cell: (row) => <ActionColumn rowData={row} />
    },
    {
      // id: 1,
      name: 'No.',
      cell: (row, rowIndex) => <>{rowIndex + 1 + perPage * (page - 1)}</>,
      sortable: false,
      width: '60px'
    },
    {
      // id: 2,
      name: 'Name',
      selector: (row) => row.folderName,
      sortable: true,
      sortField: 'folderName',
      width: '190px'
    },
    {
      // id: 1,
      name: '',
      cell: (row) => (row.statusFile == 1 ? <DefaultIcon /> : row.exportPDF ? <ClickableIcon defaultKey={row.id} /> : null),
      sortable: false,
      width: '1px'
    },
    {
      // id: 1,
      name: 'Status',
      cell: (row) => <StatusColumn rowData={row} />,
      sortable: false,
      width: '170px'
    },
    {
      // id: 3,
      name: 'Datetime',
      selector: (row) => DateTime.fromFormat(`${row.date}${row.time}`, 'yyyyMMddHHmmssSSS').toFormat('dd/MM/yyyy HH:mm:ss'),
      sortable: true,
      sortField: 'datetime',
      width: '160px'
    },
    {
      // id: 6,
      name: 'Detected',
      selector: (row) => (row.isCrackDetected ? 'Yes' : 'No'),
      sortable: true,
      sortField: 'isCrackDetected'
    },
    {
      // id: 7,
      name: 'Trigger type',
      selector: (row) => row.triggerType.charAt(0).toUpperCase() + row.triggerType.slice(1).toLocaleLowerCase(),
      sortable: true,
      sortField: 'folderName',
      width: '150px'
    },
    {
      // id: 8,
      name: 'Road name',
      selector: (row: any) => row.roadName,
      sortable: true,
      sortField: 'roadName',
      width: '200px'
    },
    {
      // id: 4,
      name: 'Latitude',
      selector: (row) => row.latitude,
      sortable: true,
      sortField: 'latitude'
    },
    {
      // id: 5,
      name: 'Longtitude',
      selector: (row) => row.longitude,
      sortable: true,
      sortField: 'longitude',
      width: '150px'
    }
  ];

  const searchValue = useAppSelector(selectDetectedLocationSearch);
  const [searchParams, setSearchParams] = useSearchParams();

  const handlePageChange = (page: number, totalRows: number) => {
    handlePage(page);
  };

  const handlePerRowsChange = (currentRowsPerPage: number, currentPage: number) => {
    handlePerPage(currentRowsPerPage);
    handlePage(currentPage);
  };

  const handleSort = (column: any, sortDirection: SortOrder) => {
    handleOrderBy(column.sortField);
    handleOrder(sortDirection);

    const newSortedData = [...(allData || [])] as DetectedLocationData[];
    newSortedData.sort((a, b) => {
      const columnValueA = column.selector ? column.selector(a) : '';
      const columnValueB = column.selector ? column.selector(b) : '';
      if (sortDirection === 'asc') {
        return columnValueA > columnValueB ? 1 : -1;
      } else {
        return columnValueA < columnValueB ? 1 : -1;
      }
    });
    setSortedData(newSortedData);
  };

  const handleSortDataBySelectedDetectedLocation = (selectedDetectedLocation: any) => {
    if (!allData || allData.length === 0 || !selectedDetectedLocation) return;

    const selectedIndex = sortedData.findIndex((item) => item.folderName === selectedDetectedLocation.folderName);
    if (selectedIndex >= 0) {
      const page = Math.floor(selectedIndex / perPage) + 1;
      handlePage(page);
    }
  };
  useEffect(() => {
    handleSortDataBySelectedDetectedLocation(detectedLocationDetail);
  }, [detectedLocationDetail?.id]);

  const handleRowClicked = (row: DetectedLocationData) => {
    dispatch(setDetectedLocationDetail(row));
  };

  const handleDelete = () => {
    if (window.confirm(`Are you sure you want to delete:\r ${selectedRows.length}?`)) {
      setToggleCleared(!toggleCleared);
      setSortedData(differenceBy(data, selectedRows, 'id'));
    }
  };
  const handleRowSelected = useCallback((state: any) => {
    dispatch(setSelectedIds(state.selectedRows));
    setSelectedRows(state.selectedRows);
  }, []);

  const contextActions = useMemo(() => {
    return <Button onClick={handleDelete}>Delete</Button>;
  }, [sortedData, selectedRows, toggleCleared]);

  return (
    <div className="table-responsive">
      <DataTable
        noHeader={true}
        pagination
        columns={columns}
        data={sortedData}
        paginationTotalRows={total}
        selectableRows
        contextActions={contextActions}
        onSelectedRowsChange={handleRowSelected}
        clearSelectedRows={toggleCleared}
        defaultSortFieldId={orderBy}
        defaultSortAsc={order === 'asc'}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handlePerRowsChange}
        onSort={handleSort}
        onRowClicked={handleRowClicked}
        paginationDefaultPage={page}
        paginationPerPage={perPage}
        customStyles={{
          rows: {
            style: {
              marginBottom:
                total && perPage && page && total % perPage === 1 && Math.floor(total / perPage) + 1 === page
                  ? '70px'
                  : total && perPage && page && total % perPage === 2 && Math.floor(total / perPage) + 1 === page
                  ? '20px'
                  : 0
            }
          }
        }}
        conditionalRowStyles={[
          {
            when: (row) => row.folderName == searchParams.get('selectFolder'),
            style: {
              backgroundColor: 'rgb(255 114 0 / 54%)'
            }
          }
        ]}
      />
    </div>
  );
};

export default React.memo(DetectedLocationTable);
