import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import MyLoader from '../../components/MyLoader';
import DetectedLocationDetail from '../../features/detectedLocation/components/core/DetectedLocationDetail';
import {
  generatePanoImageByFolderName,
  getDetectedLocationDetail,
  getDrawRectangleImageById,
  getExportPDF,
  getPanoImageByDetectedLocationId
} from '../../features/detectedLocation/detectedLocationApi';
import { resetDetectedLocationDetail, selectDetectedLocationDetail, selectLoading } from '../../features/detectedLocation/detectedLocationSlice';
import { message } from 'antd';

const DetectedLocationDetailPage = () => {
  const dispatch = useAppDispatch();
  const detectedLocationDetail = useAppSelector(selectDetectedLocationDetail);
  const loading = useAppSelector(selectLoading);
  const { id } = useParams<{ id: string }>();
  const [messageApi, contextHolder] = message.useMessage();
  const error = () => {
    messageApi.open({
      type: 'error',
      content: 'Can not get datasets in cold storage'
    });
  };
  useEffect(() => {
    if (id) {
      dispatch(getDetectedLocationDetail({ id })).then((res) => {
        if (res.meta.requestStatus === 'rejected') {
          error();
        }
      });
      dispatch(getExportPDF({ key: id }));
      dispatch(generatePanoImageByFolderName({ id: id }));
    }
    return () => {
      dispatch(resetDetectedLocationDetail());
    };
  }, [id]);

  return (
    <>
      {contextHolder}
      <MyLoader active={loading}>{detectedLocationDetail && <DetectedLocationDetail></DetectedLocationDetail>}</MyLoader>
    </>
  );
};

export default DetectedLocationDetailPage;
