import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { selectChangePasswordLoading, selectIsShowModal, toggleShowModal } from '../../authSlice';
import { Button, Container, Form, Modal, Row, InputGroup } from 'react-bootstrap';
import { SubmitHandler, useForm } from 'react-hook-form';
import Col from 'react-bootstrap/Col';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { updatePassword } from '../../authApi';
import { toast } from 'react-toastify';
import { ErrorI } from '../../authType';
import { WRONG_PASSWORD_CODE } from '../../../../utils/const';
import { useState } from 'react';

type ChangePasswordFormValues = {
  currentPassword: string;
  newPassword: string;
  confirmPassword: string;
};

const schema = yup.object().shape({
  currentPassword: yup.string().required('Current Password is required.'),
  newPassword: yup
    .string()
    .required('New Password is required.')
    .notOneOf(
      [yup.ref('currentPassword'), 'null'],
      'New password should different current password.'
    ),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('newPassword'), ''], 'Passwords do not match.')
    .required('Confirm Password is required.')
});

const ChangePasswordModal = () => {
  const isShowModal = useAppSelector(selectIsShowModal);
  const isLoading = useAppSelector(selectChangePasswordLoading);
  const [isShowCurrentPassword, setIsShowCurrentPassword] = useState<boolean>(false);
  const [isShowNewPassword, setIsShowNewPassword] = useState<boolean>(false);
  const [isShowConfirmPassword, setIsShowConfirmPassword] = useState<boolean>(false);

  const [password, setPassword] = useState<string>('');

  const dispatch = useAppDispatch();
  const handleClose = () => {
    dispatch(toggleShowModal(false));
  };
  const {
    handleSubmit,
    register,
    formState: { errors },
    setError,
    reset
  } = useForm<ChangePasswordFormValues>({
    mode: 'onBlur',
    resolver: yupResolver(schema)
  });

  const onSubmit: SubmitHandler<ChangePasswordFormValues> = (data) => {
    dispatch(
      updatePassword({
        newPassword: data.newPassword,
        currentPassword: data.currentPassword,
        confirmPassword: data.confirmPassword
      })
    ).then((res) => {
      if (
        res.meta.requestStatus == 'rejected' &&
        res.payload &&
        (res.payload as ErrorI).statusCode == WRONG_PASSWORD_CODE
      ) {
        const message = (res.payload as ErrorI).message;
        return setError('currentPassword', { type: 'custom', message: message });
      } else if (
        res.meta.requestStatus == 'rejected' &&
        (res.payload as ErrorI).statusCode != WRONG_PASSWORD_CODE
      ) {
        const message = (res.payload as ErrorI).message;

        toast.error(message, {
          position: 'top-center',
          autoClose: 3000,
          theme: 'light'
        });
      }
      if (res.meta.requestStatus == 'fulfilled') {
        reset();
        handleClose();
        toast.success('Update password succesfully', {
          position: 'top-center',
          autoClose: 3000,
          theme: 'light'
        });
      }
    });
  };
  return (
    <Modal show={isShowModal} onHide={handleClose} fullscreen={'md-down'} size={'lg'}>
      <Modal.Header closeButton>
        <Modal.Title>Change password</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container>
          <Form>
            <Row className="mb-3">
              <Form.Group as={Col} lg="12" controlId="validationCustom09">
                <Form.Label>Current password</Form.Label>
                <InputGroup className="mb-3">
                  <Form.Control
                    isInvalid={!!errors.currentPassword}
                    type={isShowCurrentPassword ? 'text' : 'password'}
                    {...register('currentPassword')}
                    maxLength={100}
                  />
                  <InputGroup.Text
                    id="basic-addon1"
                    role="button"
                    onClick={() => {
                      setIsShowCurrentPassword(!isShowCurrentPassword);
                    }}>
                    <i
                      className={
                        isShowCurrentPassword
                          ? 'bi bi-eye text-primary'
                          : 'bi bi-eye-slash text-primary'
                      }></i>
                  </InputGroup.Text>
                  {errors.currentPassword && (
                    <Form.Control.Feedback type="invalid">
                      {errors.currentPassword?.message}
                    </Form.Control.Feedback>
                  )}
                </InputGroup>
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} lg="12" controlId="validationCustom10">
                <Form.Label>New password</Form.Label>
                <InputGroup className="mb-3">
                  <Form.Control
                    isInvalid={!!errors.newPassword}
                    type={isShowNewPassword ? 'text' : 'password'}
                    {...register('newPassword')}
                    maxLength={100}
                  />
                  <InputGroup.Text
                    id="basic-addon1"
                    role="button"
                    onClick={() => {
                      setIsShowNewPassword(!isShowNewPassword);
                    }}>
                    <i
                      className={
                        isShowNewPassword
                          ? 'bi bi-eye text-primary'
                          : 'bi bi-eye-slash text-primary'
                      }></i>
                  </InputGroup.Text>
                  {errors.newPassword && (
                    <Form.Control.Feedback type="invalid">
                      {errors.newPassword?.message}
                    </Form.Control.Feedback>
                  )}
                </InputGroup>
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} lg="12" controlId="validationCustom11">
                <Form.Label>Confirm password</Form.Label>
                <InputGroup className="mb-3">
                  <Form.Control
                    type={isShowConfirmPassword ? 'text' : 'password'}
                    {...register('confirmPassword')}
                    maxLength={100}
                    isInvalid={!!errors.confirmPassword}
                  />
                  <InputGroup.Text
                    id="basic-addon1"
                    role="button"
                    onClick={() => {
                      setIsShowConfirmPassword(!isShowConfirmPassword);
                    }}>
                    <i
                      className={
                        isShowConfirmPassword
                          ? 'bi bi-eye text-primary'
                          : 'bi bi-eye-slash text-primary'
                      }></i>
                  </InputGroup.Text>
                  {errors.confirmPassword && (
                    <Form.Control.Feedback type="invalid">
                      {errors.confirmPassword?.message}
                    </Form.Control.Feedback>
                  )}
                </InputGroup>
              </Form.Group>
            </Row>
          </Form>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button disabled={isLoading} variant="primary" onClick={handleSubmit(onSubmit)}>
          {isLoading ? 'Loading...' : `Update`}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ChangePasswordModal;
