import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import authInstance from '../../Axios/authInstance';

export const getNewDataSet = createAsyncThunk<{ message: string }, undefined, { state: RootState }>(
  'syncdata/getNewDataset',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await authInstance.get(`/api/s3/process-s3-files`);
      return data;
      // eslint-disable-next-line
    } catch (error: any) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
