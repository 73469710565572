import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { fetchLocation, fetchOneMapData, getDetectedLocationWithinRadius } from './onemapApi';
import { ILocationOnemap, OneMapState } from './onemapType';

const oneMapSlice = createSlice({
  name: 'oneMap',
  initialState: {
    mapCenter: [1.3521, 103.8198],
    zoomLevel: 11,
    searchResult: [],
    detectedLocation: []
  } as OneMapState,
  reducers: {
    setMapCenter: (state, action: PayloadAction<[number, number]>) => {
      state.mapCenter = action.payload;
    },
    setZoomLevel: (state, action: PayloadAction<number>) => {
      state.zoomLevel = action.payload;
    },
    setSelectedLocation: (state, action: PayloadAction<ILocationOnemap>) => {
      state.selectedLocation = action.payload;
    },
    setSelectedLocationB: (state, action: PayloadAction<ILocationOnemap>) => {
      state.selectedLocationB = action.payload;
    },
    clearSelectedLocation: (state) => {
      state.selectedLocation = undefined;
    },
    clearSelectedLocationB: (state) => {
      state.selectedLocationB = undefined;
    }
    // ...
  },
  extraReducers: (builder) => {
    builder.addCase(fetchOneMapData.pending, (state) => {
      // update your state with the fetched data
      state.loading = true;
    });
    builder.addCase(fetchOneMapData.fulfilled, (state, action) => {
      // update your state with the fetched data
      state.loading = false;
      state.searchResult = action.payload.results;
    });
    builder.addCase(fetchOneMapData.rejected, (state) => {
      // handle errors
      state.loading = false;
    });
    builder.addCase(getDetectedLocationWithinRadius.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getDetectedLocationWithinRadius.fulfilled, (state, action) => {
      state.loading = false;
      state.detectedLocation = action.payload;
    });
    builder.addCase(getDetectedLocationWithinRadius.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
    builder.addCase(fetchLocation.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchLocation.fulfilled, (state, action) => {
      state.loading = false;
      state.searchResult = action.payload.GeocodeInfo.map((res) => {
        return {
          ...res,
          BUILDING: res.BUILDINGNAME == 'null' ? `${res.LATITUDE},${res.LONGITUDE}` : res.BUILDINGNAME
        };
      });
    });
    builder.addCase(fetchLocation.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
  }
});
export const selectSearchResult = (state: RootState) => state.oneMap.searchResult;
export const selectLoading = (state: RootState) => state.oneMap.loading;
export const selectSelectedLocation = (state: RootState) => state.oneMap.selectedLocation;
export const selectSelectedLocationB = (state: RootState) => state.oneMap.selectedLocationB;
export const selectDetectedLocation = (state: RootState) => state.oneMap.detectedLocation;

export const { setMapCenter, setZoomLevel, setSelectedLocation, clearSelectedLocation, setSelectedLocationB, clearSelectedLocationB } =
  oneMapSlice.actions;
export { fetchOneMapData };
export default oneMapSlice.reducer;
