import React, { useEffect } from 'react';
import { Button, Container, Form, Row, Modal } from 'react-bootstrap';
import { UpdateTelegramInfo } from '../../authType';
import { useForm, SubmitHandler } from 'react-hook-form';
import Col from 'react-bootstrap/Col';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { selectLoading, selectTelegramInfo } from '../../authSlice';
import { yupResolver } from '@hookform/resolvers/yup';
import yup from '../../../../utils/yupGlobal';
import { getTelegramInfo, updateTelegramInfo, checkTelegramIntegration, unlinkTelegramInfo } from '../../authApi';

const schema = yup.object().shape({
  token: yup.string().required('Please enter telegram token')
});
const UpdateTelegramForm = () => {
  const telegramInfo = useAppSelector(selectTelegramInfo);
  const dispatch = useAppDispatch();
  const loading = useAppSelector(selectLoading);
  const [showSuccess, setShowSuccess] = React.useState(false);
  const [showSuccessTest, setShowSuccessTest] = React.useState(false);
  const [showNotification, setShowNotification] = React.useState(false);
  const [token, setToken] = React.useState('');
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    reset
  } = useForm<UpdateTelegramInfo>({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: {
      token: telegramInfo?.token || ''
    }
  });

  useEffect(() => {
    dispatch(getTelegramInfo());
  }, [dispatch]);

  useEffect(() => {
    if (telegramInfo) {
      reset({
        token: telegramInfo.token
      });
      setToken(telegramInfo.token);
    }
  }, [telegramInfo, reset]);

  const onSubmit: SubmitHandler<UpdateTelegramInfo> = (data) => {
    dispatch(updateTelegramInfo({ ...data })).then((res) => {
      if (res.meta.requestStatus === 'fulfilled') {
        setShowSuccessTest(true);
        setTimeout(() => {
          setShowSuccessTest(false);
        }, 1000);
        if ((res.payload as any)?.botInfo) {
          setShowNotification(true);
        } else {
          setShowNotification(false);
        }
      }
    });
  };

  const handleTestIntegrate = (data: any) => {
    dispatch(checkTelegramIntegration({ ...data })).then((res) => {
      if (res.meta.requestStatus === 'fulfilled') {
        setShowSuccessTest(true);
        setTimeout(() => {
          setShowSuccessTest(false);
        }, 1000);
        if (res.payload === true) {
          setShowNotification(true);
        } else {
          setShowNotification(false);
        }
      }
    });
  };

  const handleUnlinkBot = () => {
    dispatch(unlinkTelegramInfo());
  };

  return (
    <Container>
      <Modal show={showSuccessTest}>
        <Modal.Header closeButton>
          <Modal.Title>Telegram Notification</Modal.Title>
        </Modal.Header>
        {showNotification ? <Modal.Body>Send notification to channel successfully!</Modal.Body> : <Modal.Body>API Key is wrong!</Modal.Body>}
      </Modal>
      {!telegramInfo || (telegramInfo && !telegramInfo.botInfo) ? (
        <Form noValidate onSubmit={handleSubmit(onSubmit)}>
          <Row>
            <Col sm={{ span: '8', offset: 0 }}>
              <Form.Group as={Col} controlId="validationCustom08">
                <Form.Label>API Key</Form.Label>
                <Form.Control
                  isInvalid={!!errors.token}
                  type="text"
                  {...register('token')}
                  maxLength={200}
                  width={'large'}
                  onChange={(e) => {
                    setToken(e.target.value);
                  }}
                />
                {errors.token && <Form.Control.Feedback type="invalid">{errors.token?.message}</Form.Control.Feedback>}
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col className="d-flex justify-content-end">
              <Button disabled={loading} variant="primary" onClick={handleSubmit(onSubmit)}>
                {loading ? 'Loading...' : 'Update'}
              </Button>
            </Col>
          </Row>
        </Form>
      ) : (
        <Form noValidate>
          <Row>
            <Col sm={{ span: '8', offset: 0 }}>
              <Form.Group as={Col} controlId="validationCustom08">
                <Form.Label>Bot Name</Form.Label>

                <Form.Control readOnly type="text" maxLength={100} value={telegramInfo.botInfo.firstName} />
                <div style={{ marginTop: '10px' }}>
                  <a href={`https://t.me/${telegramInfo.botInfo.userName}`} target="_blank" rel="noreferrer">
                    Open Telegram
                  </a>
                </div>
              </Form.Group>
            </Col>
          </Row>
          <br />
          <Row>
            <Col sm={{ span: '8', offset: 0 }}>
              <Form.Group as={Col} controlId="validationCustom08">
                <Form.Label>API Token</Form.Label>
                <Form.Control readOnly type="text" maxLength={100} value={telegramInfo.token} />
              </Form.Group>
            </Col>
            <Col sm={{ span: '4', offset: 0 }}>
              <Button disabled={!telegramInfo.chatId} variant="primary" onClick={() => handleTestIntegrate({ token })} style={{ marginTop: '31px' }}>
                {'Test'}
              </Button>
            </Col>
          </Row>
          <Row>
            <Col className="d-flex justify-content-end">
              <Button disabled={loading} variant="primary" onClick={() => handleUnlinkBot()}>
                {loading ? 'Loading...' : 'Unlink'}
              </Button>
            </Col>
          </Row>
        </Form>
      )}
    </Container>
  );
};

export default UpdateTelegramForm;
