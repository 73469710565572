import React, { useState } from 'react';
import { Form, FormControl, Button, Container, Row, Col } from 'react-bootstrap';
import { toast, ToastContent } from 'react-toastify';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { forgotPasswordI, userForgotPassword, userLogin } from '../../authApi';
import { selectLoading } from '../../authSlice';
import { NavLink } from 'react-router-dom';
interface LoginProps {
  children?: React.ReactNode;
}

const ForgotPassword: React.FC<LoginProps> = () => {
  const [email, setEmail] = useState<string>('');
  const dispatch = useAppDispatch();
  const isLoading = useAppSelector(selectLoading);
  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const payload: forgotPasswordI = { email };
    if (!email) {
      return toast.error('Please enter all information', {
        position: 'top-center',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light'
      });
    }
    dispatch(userForgotPassword(payload)).then((res) => {
      if (res.meta.requestStatus == 'rejected') {
        toast.error(res.payload as ToastContent, {
          position: 'top-center',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light'
        });
      }
      if (res.meta.requestStatus == 'fulfilled') {
        toast.success('Email sent!', {
          position: 'top-center',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light'
        });
      }
    });
  };
  return (
    <>
      <Container className="position-absolute top-50 start-50 translate-middle">
        <Row className="justify-content-center">
          <Col md="5" className="shadow p-5 mb-5 bg-body rounded rounded-4">
            <Row>
              <Col className="mb-4 d-flex justify-content-center" lg="12">
                <div className="fs-1">PathWatcher</div>
              </Col>
              <Col className="mb-1 d-flex justify-content-center" lg="12">
                <div className="fs-4">Forgot password</div>
              </Col>
              <Col className="mb-3 d-flex justify-content-center" lg="12">
                <div className="fs-6 text-secondary text-opacity-50">Enter your email and we will send your credential! </div>
              </Col>
            </Row>

            <Form onSubmit={handleSubmit} autoComplete="off">
              <Form.Group controlId="formBasicEmail" className="mb-3">
                <Form.Label>Email</Form.Label>
                <FormControl type="email" placeholder="Enter email" value={email} onChange={(e) => setEmail(e.target.value)} size="lg" />
              </Form.Group>
              <div className="d-grid gap-2 justify-content-center mb-2">
                <NavLink className={' text-decoration-none'} to={'/login'}>
                  Back to Login?
                </NavLink>
              </div>
              <div className="d-grid gap-2">
                <Button disabled={isLoading} variant="primary" size="lg" type="submit">
                  {isLoading ? 'Loading…' : 'Send email'}
                </Button>
              </div>
            </Form>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default ForgotPassword;
