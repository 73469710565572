import { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import DataTable, { TableColumn } from 'react-data-table-component';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { getAllUsers } from '../../authApi';
import { DateTime } from 'luxon';
import ActionApproveColumn from './ActionApproveColumn';
import ActionRoleColumn from './ActionRoleColumn';
import ReactLoading from 'react-loading';

const AccountCredentials = () => {
  const [data, setData] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  useEffect(() => {
    setLoading(true);
    dispatch(getAllUsers()).then((res: any) => {
      if (res.meta.requestStatus === 'fulfilled') {
        setData(res.payload);
        setLoading(false);
      } else {
        setLoading(false);
      }
    });
  }, [dispatch]);

  const CustomLoading = () => {
    return loading ? <ReactLoading type={'spin'} color="#000" /> : null;
  };

  const columns: TableColumn<any>[] = [
    {
      name: 'No.',
      cell: (row, rowIndex) => <>{rowIndex + 1}</>,
      sortable: false,
      width: '70px'
    },
    {
      name: 'Email',
      selector: (row) => row.email,
      sortable: true,
      sortField: 'email',
      width: '300px'
    },
    {
      name: 'Name',
      selector: (row) => row.firstName + ' ' + row.lastName,
      sortable: true,
      sortField: 'name',
      width: '250px'
    },
    {
      name: 'Created At',
      selector: (row) => DateTime.fromISO(row.createdAt).toFormat('dd/MM/yyyy HH:mm'),
      sortable: true,
      sortField: 'createdAt',
      width: '180px'
    },
    {
      name: 'Approve',
      width: '200px',
      cell: (row) => <ActionApproveColumn rowData={row} />
    },
    {
      name: 'Role',
      width: '250px',
      cell: (row) => <ActionRoleColumn rowData={row} />
    }
  ];
  return (
    <Container>
      <div className="table-responsive">
        <DataTable
          noHeader={true}
          highlightOnHover={true}
          pointerOnHover={true}
          striped={true}
          pagination={true}
          progressPending={loading}
          progressComponent={<CustomLoading></CustomLoading>}
          columns={columns}
          data={data || []}
        />
      </div>
    </Container>
  );
};

export default AccountCredentials;
