import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import authInstance from '../../Axios/authInstance';
import { UploadDatasetResponse } from './uploadFolderType';
import { saveAs } from 'file-saver';

export const uploadDataset = createAsyncThunk<UploadDatasetResponse, FormData, { state: RootState }>(
  'uploadFolder/uploadDataset',
  async (formData, { rejectWithValue }) => {
    // for (let attempt = 0; attempt < 3; attempt++) {
    try {
      const { data } = await authInstance.post(`/api/s3/upload-dataset`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      return data;
    } catch (error: any) {
      // if (attempt === 2) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
      // }
      // await new Promise((resolve) => setTimeout(resolve, 500));
    }
    // }
  }
);

export const uploadBatchPavementFiles = createAsyncThunk<UploadDatasetResponse, FormData, { state: RootState }>(
  'uploadFolder/uploadBatchPavementFiles',
  async (formData, { rejectWithValue }) => {
    try {
      const { data } = await authInstance.post(`/api/s3/upload-batch-pavement-files`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      return data;
    } catch (error: any) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const uploadListIds = createAsyncThunk<{ file: string }, { ids: string[] }, { state: RootState }>(
  'uploadFolder/pdf',
  async ({ ids }, { rejectWithValue }) => {
    try {
      const response = await authInstance.post(
        `/api/report-pdf/zip`,
        { ids },
        {
          responseType: 'blob'
        }
      );

      const zipBlob = new Blob([response.data], { type: 'application/zip' });
      const fileName = `${new Date().getTime()}.zip`;
      saveAs(zipBlob, fileName);

      return { file: fileName };
    } catch (error: any) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const downloadDatasets = createAsyncThunk<{ file: string }, { ids: string[] }, { state: RootState }>(
  'uploadFolder/pdf',
  async ({ ids }, { rejectWithValue }) => {
    try {
      const response = await authInstance.post(
        `/api/report-pdf/datasets`,
        { ids },
        {
          responseType: 'blob'
        }
      );

      const zipBlob = new Blob([response.data], { type: 'application/zip' });
      const fileName = `${new Date().getTime()}.zip`;
      saveAs(zipBlob, fileName);

      return { file: fileName };
    } catch (error: any) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const migrationDatasets = createAsyncThunk<boolean, { ids: string[] }, { state: RootState }>(
  'migration/datasets',
  async ({ ids }, { rejectWithValue }) => {
    try {
      await authInstance.post(`/api/s3/migration-datasets`, { key: ids });
      return true;
    } catch (error: any) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
