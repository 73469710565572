import './App.scss';
import AuthLayout from './components/Layout/AuthLayout';
import 'bootstrap-icons/font/bootstrap-icons.css';
import LoginPage from './Pages/Login/Login';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import PrivateRoute from './components/PrivateRoute/PrivateRoute';
import HomePage from './Pages/Home/Home';
import MainLayout from './components/Layout/MainLayout';
import 'react-datepicker/dist/react-datepicker.css';
import DetectedLocationPage from './Pages/DetectdLocationPage/DetectedLocationPage';
import 'leaflet/dist/leaflet.css';
import DetectedLocationDetail from './Pages/DetectdLocationPage/DetectedLocationDetailPage';
import RegisterPage from './Pages/Register/RegisterPage';
import UploadDatasetPage from './Pages/UploadDatasetPage/UploadDatasetPage';
import ProfilePage from './Pages/ProfilePage/ProfilePage';
import ForgotPasswordPage from './Pages/ForgotPasswordPage/ForgotPasswordPage';

const router = createBrowserRouter([
  {
    path: '/',
    element: <PrivateRoute></PrivateRoute>,
    children: [
      {
        path: '',
        element: <MainLayout></MainLayout>,
        children: [
          { path: '', element: <HomePage></HomePage> },
          { path: '/detected-locations', element: <DetectedLocationPage></DetectedLocationPage> },
          {
            path: '/detected-locations/:id',
            element: <DetectedLocationDetail></DetectedLocationDetail>
          },
          {
            path: '/detected-locations/upload-datasets',
            element: <UploadDatasetPage></UploadDatasetPage>
          },
          {
            path: 'profile',
            element: <ProfilePage></ProfilePage>
          }
        ]
      }
    ]
  },
  {
    path: '/',
    element: <AuthLayout></AuthLayout>,
    children: [
      {
        path: 'login',
        element: <LoginPage></LoginPage>
      },
      {
        path: 'register',
        element: <RegisterPage></RegisterPage>
      },
      {
        path: 'forgot-password',
        element: <ForgotPasswordPage></ForgotPasswordPage>
      }
    ]
  }
]);
function App() {
  return (
    <div className="App">
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
