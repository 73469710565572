import React from 'react'
import FolderUploadDropzone from '../../features/uploadFolder/Components/FolderUploadDropzone'
import UploadDataset from '../../features/uploadFolder/Components/Core/UploadDataset';

// type Props = {}

const UploadDatasetPage = () => {
  return (
    <div><UploadDataset></UploadDataset></div>
  )
}
export default UploadDatasetPage;