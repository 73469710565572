import React, { useEffect } from 'react';
import { Button, Container, Form, InputGroup, Row, Modal } from 'react-bootstrap';
import { UpdateProfile, UpdateProfileFormValue } from '../../authType';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import Col from 'react-bootstrap/Col';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { selectLoading, selectUserProfile } from '../../authSlice';
import countryList from 'country-list';
import codes from 'country-calling-code';
import { months } from '../../../../utils/const';
import { yupResolver } from '@hookform/resolvers/yup';
import yup, { REGEX_ONLY_NUMBER } from '../../../../utils/yupGlobal';
import { updateUserProfile } from '../../authApi';
import { DateTime } from 'luxon';
const schema = yup.object().shape({
  firstName: yup.string().required('Please enter first name'),
  lastName: yup.string().required('Please enter last name'),
  // gender: yup.string().required('Please select gender'),
  location: yup.string().required('Please select location')
  // phone: yup.string().matches(REGEX_ONLY_NUMBER, { message: `Invalid phone number` })
});
const UpdateProfileForm = () => {
  const dispatch = useAppDispatch();
  const profile = useAppSelector(selectUserProfile);
  const loading = useAppSelector(selectLoading);
  const [month, setMonth] = React.useState(1);
  const [year, setYear] = React.useState(new Date().getFullYear());
  const [showSuccess, setShowSuccess] = React.useState(false);
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    reset
  } = useForm<UpdateProfileFormValue>({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: {
      lastName: profile?.lastName,
      firstName: profile?.firstName,
      location: profile?.location,
      email: profile?.email,
      gender: profile?.gender,
      phone: profile?.phone,
      phoneCode: profile?.phoneCode || 'SG'
    }
  });

  useEffect(() => {
    if (profile) {
      reset({
        ...profile,
        day: profile?.dateOfBirth ? DateTime.fromFormat(profile?.dateOfBirth, 'yyyy-mm-dd').get('day') : 2,
        month: profile?.dateOfBirth ? DateTime.fromFormat(profile?.dateOfBirth, 'yyyy-MM-dd').get('month') : 1,
        year: profile?.dateOfBirth ? DateTime.fromFormat(profile?.dateOfBirth, 'yyyy-mm-dd').get('year') : 2023
      });
    }
  }, [profile]);
  const onSubmit: SubmitHandler<UpdateProfile> = (data) => {
    dispatch(updateUserProfile({ ...data, dateOfBirth: `${data.day}/${data.month}/${data.year}` })).then((res: any) => {
      if (res.meta.requestStatus === 'fulfilled') {
        setShowSuccess(true);
        setTimeout(() => {
          setShowSuccess(false);
        }, 1000);
      }
    });
  };
  const getYears = () => {
    const currentYear = new Date().getFullYear();
    const years = [];
    let startYear = currentYear - 65;
    while (startYear <= currentYear) {
      years.push(startYear++);
    }
    return years.sort((a, b) => b - a);
  };
  const getDaysInMonth = (month: number, year: number): number[] | undefined => {
    if (!month) {
      return;
    }
    const date = new Date(year, month - 1, 1);
    const days = [];
    while (date.getMonth() === month - 1) {
      days.push(date.getDate());
      date.setDate(date.getDate() + 1);
    }
    return days;
  };

  return (
    <Container>
      <Modal show={showSuccess}>
        <Modal.Header closeButton>
          <Modal.Title>Profile Setting</Modal.Title>
        </Modal.Header>
        <Modal.Body>Update profile successfully!</Modal.Body>
      </Modal>

      <Form noValidate onSubmit={handleSubmit(onSubmit)}>
        <Row className="mb-3">
          <Form.Group as={Col} lg="6" controlId="validationCustom08">
            <Form.Label>Email</Form.Label>
            <Form.Control readOnly type="text" maxLength={100} value={profile?.email} />
          </Form.Group>
          <Form.Group as={Col} lg="6" controlId="validationCustom08">
            <Form.Label>Location</Form.Label>
            <Form.Select {...register('location')} isInvalid={!!errors.location}>
              {countryList.getData().map((it) => {
                return (
                  <option value={it.code} key={it.code}>
                    {it.name}
                  </option>
                );
              })}
            </Form.Select>
            {errors.location && <Form.Control.Feedback type="invalid">{errors.location?.message}</Form.Control.Feedback>}
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col} lg="4" controlId="validationCustom08">
            <Form.Label>First name</Form.Label>
            <Form.Control isInvalid={!!errors.firstName} type="text" {...register('firstName')} maxLength={100} />
            {errors.firstName && <Form.Control.Feedback type="invalid">{errors.firstName?.message}</Form.Control.Feedback>}
          </Form.Group>
          <Form.Group as={Col} lg="4" controlId="validationCustom08">
            <Form.Label>Last name</Form.Label>
            <Form.Control type="text" isInvalid={!!errors.lastName} {...register('lastName')} maxLength={100} />
            {errors.lastName && <Form.Control.Feedback type="invalid">{errors.lastName?.message}</Form.Control.Feedback>}
          </Form.Group>
          <Form.Group as={Col} lg="4" controlId="validationCustom08">
            <Form.Label>Gender</Form.Label>
            <Form.Select {...register('gender')}>
              <option value="Male">Male</option>
              <option value="Female">Female</option>
            </Form.Select>
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col} lg="6" controlId="validationCustom08">
            <Form.Label>Phone</Form.Label>
            <InputGroup className="mb-3">
              <Controller
                name="phoneCode"
                control={control}
                render={() => (
                  <Form.Select {...register('phoneCode')} className="flex-grow-1">
                    {codes.map((it) => {
                      return (
                        <option value={it.isoCode2} key={it.isoCode2}>
                          {` (+${it.countryCodes}) ${it.country}`}
                        </option>
                      );
                    })}
                  </Form.Select>
                )}
              />
              <Form.Control
                className="flex-grow-4"
                style={{ flexGrow: 3 }}
                type="text"
                {...register('phone')}
                maxLength={100}
                // isInvalid={!!errors.phone}
              />
              {/* {errors.phone && (
                <Form.Control.Feedback type="invalid">
                  {errors.phone?.message}
                </Form.Control.Feedback>
              )} */}
            </InputGroup>
          </Form.Group>
          <Form.Group as={Col} lg="6" controlId="validationCustom08">
            <Form.Label>Date of birth</Form.Label>
            <Row>
              <Col sm={{ span: '3', offset: 0 }} className="pe-2">
                <Form.Select {...register('day')} className="flex-grow-1">
                  {getDaysInMonth(month, year)?.map((it, index) => {
                    return (
                      <option value={it} key={index}>
                        {it}
                      </option>
                    );
                  })}
                </Form.Select>
              </Col>
              <Col sm={{ span: '3', offset: 0 }} className="ps-0 pe-2">
                <Form.Select {...register('month')} onChange={(e) => setMonth(parseInt(e.target.value))} className="flex-grow-1">
                  {months.map((it, index) => {
                    return (
                      <option value={index + 1} key={it}>
                        {it}
                      </option>
                    );
                  })}
                </Form.Select>
              </Col>
              <Col sm={{ span: '6', offset: 0 }} className="ps-0">
                <Form.Select {...register('year')} onChange={(e) => setYear(parseInt(e.target.value))} className="flex-grow-1">
                  {getYears().map((it) => {
                    return (
                      <option value={it} key={it}>
                        {it}
                      </option>
                    );
                  })}
                </Form.Select>
              </Col>
            </Row>
          </Form.Group>
        </Row>
        <Row>
          <Col className="d-flex justify-content-end">
            <Button disabled={loading} variant="primary" onClick={handleSubmit(onSubmit)}>
              {loading ? 'Loading...' : ` Save change`}
            </Button>
          </Col>
        </Row>
      </Form>
    </Container>
  );
};

export default UpdateProfileForm;
