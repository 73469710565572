import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers';
import './style.css';
import dayjs from 'dayjs';

export default function TimePickerStartTime({ startTime, setStartTime, defaultValue }: any) {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoContainer components={['TimePicker']}>
        <div className="timePickerContainer" style={{ width: '100%', marginTop: '7px' }}>
          {startTime ? (
            <TimePicker
              viewRenderers={{
                hours: renderTimeViewClock,
                minutes: renderTimeViewClock,
                seconds: renderTimeViewClock
              }}
              onChange={(value) => {
                setStartTime(dayjs(value).format('HH:mm:ss'));
              }}
              defaultValue={dayjs(`2024-06-18T${defaultValue}`)}
            />
          ) : (
            <TimePicker
              viewRenderers={{
                hours: renderTimeViewClock,
                minutes: renderTimeViewClock,
                seconds: renderTimeViewClock
              }}
              onChange={(value) => {
                setStartTime(dayjs(value).format('HH:mm:ss'));
              }}
            />
          )}
        </div>
      </DemoContainer>
    </LocalizationProvider>
  );
}
