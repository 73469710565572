import React, { useState } from 'react';
import { Form } from 'react-bootstrap'; // Assuming you're using React Bootstrap
import { DetectedLocationData } from '../detectedLocationType';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { updateStatus } from '../detectedLocationApi';
import { message } from 'antd';

type props = {
  rowData: DetectedLocationData;
};

function StatusSelect({ rowData }: props) {
  const [selectedStatus, setSelectedStatus] = useState(rowData.status);
  const dispatch = useAppDispatch();
  const [messageApi, contextHolder] = message.useMessage();

  const getBackgroundColor = (value: number) => {
    switch (value) {
      case 1:
        return '#fbc02d';
      case 2:
        return '#f77c80';
      case 3:
        return '#66bb6a';
      default:
        return 'white';
    }
  };

  const success = (text: string) => {
    messageApi.open({
      type: 'success',
      content: text
    });
  };

  return (
    <>
      {contextHolder}
      <Form.Select
        value={selectedStatus}
        onChange={(e) => {
          setSelectedStatus(parseInt(e.target.value));
          dispatch(updateStatus({ id: rowData.id, status: parseInt(e.target.value) })).then((res: any) => {
            if (res.meta.requestStatus === 'fulfilled') {
              success('Status updated successfully');
            }
          });
        }}
        defaultValue={selectedStatus}
        style={{ backgroundColor: getBackgroundColor(selectedStatus) }}>
        <option value={0} style={{ backgroundColor: 'white' }}>
          Status
        </option>
        <option value={1} style={{ backgroundColor: 'white' }}>
          In Process
        </option>
        <option value={2} style={{ backgroundColor: 'white' }}>
          Take Action
        </option>
        <option value={3} style={{ backgroundColor: 'white' }}>
          Completed
        </option>
      </Form.Select>
    </>
  );
}

export default StatusSelect;
